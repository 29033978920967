@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.q-img {
  &.with-lightbox {
    &:not(.no-scale) {
      &:hover {
        & img {
          transform: scale(1.1);
        }
      }
    }
    cursor: pointer;
    & img {
      transition: $generic-hover-transition;
    }
  }
}
