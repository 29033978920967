@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@media (max-width: $breakpoint-xs-max) {
  .select-request {
    width: 100%;
  }
}

@media (min-width: $breakpoint-xs-max) {
  .select-request {
    width: 550px;
  }
}

@media (min-width: $breakpoint-sm-max) {
  .select-request {
    width: 600px;
  }
}

@media (min-width: $breakpoint-md-max) {
  .select-request {
    width: 700px;
  }
}
