@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.q-editor {
  $editor-h1: $h3;
  $editor-h2: $h4;

  transition: all 100ms ease-in-out;
  overflow: hidden;

  &__content {
    // Font sizes override
    h1,
    .h1 {
      font-size: map-get($editor-h1, 'size');
      line-height: map-get($editor-h1, 'line-height');
      letter-spacing: map-get($editor-h1, 'letter-spacing');
      font-weight: map-get($editor-h1, 'weight');
    }
    h2,
    .h2 {
      font-size: map-get($editor-h2, 'size');
      line-height: map-get($editor-h2, 'line-height');
      letter-spacing: map-get($editor-h2, 'letter-spacing');
      font-weight: map-get($editor-h2, 'weight');
    }
    p {
      margin: 0 0 8px;
    }
    ol,
    ul {
      margin: 10px 0;
    }
  }

  // No dropdown arrow
  &.no-dropdown {
    & .q-editor {
      &__toolbar {
        &-group {
          & > .q-btn-item {
            & .q-btn-dropdown__arrow {
              display: none;
            }
          }
        }
      }
    }
  }

  & .q-editor {
    &__toolbars {
      &-container {
        overflow: hidden;
        transition: transform 100ms ease-in;
        transform-origin: top;
      }
    }
    &__toolbar {
      & + .q-editor__toolbar {
        & .text-undefined {
          font-size: map-get($caption, 'size');
          color: $placeholder-color;
        }
        & .q-editor__link-input {
          font-size: map-get($body2, 'size');
        }
      }
    }
  }
  &:not(&:active, &:focus-within, &:focus-visible, &.is-focused) {
    transform: scaleY(1);
    & .q-editor {
      &__toolbars-container {
        transform: scaleY(0);
      }
      &__toolbar {
        height: 0;
        min-height: 0;
      }
    }
  }
  &:active,
  &:focus-within,
  &:focus-visible,
  &.is-focused {
    & .q-editor {
      &__toolbars-container {
        transform: scaleY(1);
      }
      &__toolbar {
        height: 32px;
        min-height: auto;
      }
    }
  }
}
