@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.q-drawer {
  &--mini {
    & .drawer {
      &-main {
        & .menu {
          &-item {
            width: 48px;
          }
        }
      }
    }
  }
}

// global main
.menu {
  &-item {
    height: 48px;
    min-height: 48px;
    margin-bottom: 20px;
    &:not(.round) {
      border-radius: $border;
    }
    &.settings {
      & .q-avatar {
        background-color: transparent !important;
      }
    }
    & .q-focus-helper,
    & .q-focus-helper:hover,
    & .q-focus-helper:active,
    & .q-focusable:focus > .q-focus-helper {
      opacity: 0 !important;
      background-color: transparent !important;
    }
  }
  &-logo {
    & .q-focusable:focus > .q-focus-helper {
      opacity: 0 !important;
      background-color: transparent !important;
    }
  }
}

.drawer {
  &-main {
    // dark
    &-dark {
      & .menu {
        &-request {
          color: $primary !important;
          background-color: $primary-50 !important;
          &:hover {
            background-color: $primary-100 !important;
          }
        }
        &-item {
          color: $primary-300;
          &.settings {
            background-color: $light-dimmed-background;
          }
          &.q-router-link--exact-active,
          &.q-router-link--active:not(.home),
          &:hover {
            background-color: $light-dimmed-background;
            color: $secondary-300;
          }
        }
      }
    }
    // light
    &:not(.drawer-main-dark, .drawer-settings-dark) {
      & .menu {
        &-request {
          color: white !important;
          background-color: $primary !important;
        }
        &-item {
          color: $primary-700;
          &.settings {
            background-color: $accent;
          }
          &.q-router-link--exact-active,
          &.q-router-link--active:not(.home),
          &:hover {
            background-color: $accent;
            color: $secondary;
          }
        }
      }
    }
  }

  &-settings {
    background-color: white;
    // general
    & .menu {
      &-item {
        margin-bottom: 12px;
      }
    }
    // dark
    &-dark {
      background-color: $dark-secondary;
      & .menu {
        &-request {
          color: $primary !important;
          background-color: $primary-50 !important;
          &:hover {
            background-color: $primary-100 !important;
          }
        }
        &-item {
          color: $primary-300;
          &.settings {
            background-color: $light-dimmed-background2;
          }
          &.q-router-link--exact-active,
          &.q-router-link--active:not(.home),
          &:hover {
            background-color: $light-dimmed-background2;
          }
        }
      }
    }
    // light
    &:not(.drawer-main-dark, .drawer-settings-dark) {
      & .menu {
        &-request {
          color: white !important;
          background-color: $primary !important;
        }
        &-item {
          color: $primary-700;
          &.settings {
            background-color: $accent;
          }
          &.q-router-link--exact-active,
          &.q-router-link--active:not(.home),
          &:hover {
            background-color: $accent;
            color: $secondary;
          }
        }
      }
    }
  }
}
