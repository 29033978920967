.ratio {
  &-1 {
    aspect-ratio: 1;
  }
  &-4-3 {
    aspect-ratio: 4/3;
  }
  &-16-9 {
    aspect-ratio: 16/9;
  }
}
