@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.q-table {
  &__top {
    padding: 6px $card-base-padding !important;
  }
  &__bottom {
    &--nodata {
      padding: 40px 32px !important;
    }
  }
  & tbody {
    & > tr {
      height: 72px !important;
    }
  }
  & thead {
    & > tr {
      height: 44px !important;
      & > th {
        &.sortable {
          & > .q-table__sort-icon {
            padding: 1px;
            border-radius: 50%;
            color: $primary-500;
            &:hover {
              color: $primary-900;
              background-color: $primary-300;
            }
          }
        }
        &.sorted {
          & > .q-table__sort-icon {
            padding: 1px;
            border-radius: 50%;
            color: $primary-600;
          }
        }
      }
    }
  }
  th,
  td {
    &:first-child {
      padding-left: $card-base-padding !important;
    }
    &:last-child {
      padding-right: $card-base-padding !important;
    }
  }
}
