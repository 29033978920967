@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.padding {
  &-1 {
    &a {
      padding: map-get($gm-size, 1);
    }
    &t {
      padding-top: map-get($gm-size, 1);
    }
    &b {
      padding-bottom: map-get($gm-size, 1);
    }
    &l {
      padding-left: map-get($gm-size, 1);
    }
    &r {
      padding-right: map-get($gm-size, 1);
    }
    &x {
      padding-left: map-get($gm-size, 1);
      padding-right: map-get($gm-size, 1);
    }
    &y {
      padding-top: map-get($gm-size, 1);
      padding-bottom: map-get($gm-size, 1);
    }
  }

  &-2 {
    &a {
      padding: map-get($gm-size, 2);
    }
    &t {
      padding-top: map-get($gm-size, 2);
    }
    &b {
      padding-bottom: map-get($gm-size, 2);
    }
    &l {
      padding-left: map-get($gm-size, 2);
    }
    &r {
      padding-right: map-get($gm-size, 2);
    }
    &x {
      padding-left: map-get($gm-size, 2);
      padding-right: map-get($gm-size, 2);
    }
    &y {
      padding-top: map-get($gm-size, 2);
      padding-bottom: map-get($gm-size, 2);
    }
  }

  &-3 {
    &a {
      padding: map-get($gm-size, 3);
    }
    &t {
      padding-top: map-get($gm-size, 3);
    }
    &b {
      padding-bottom: map-get($gm-size, 3);
    }
    &l {
      padding-left: map-get($gm-size, 3);
    }
    &r {
      padding-right: map-get($gm-size, 3);
    }
    &x {
      padding-left: map-get($gm-size, 3);
      padding-right: map-get($gm-size, 3);
    }
    &y {
      padding-top: map-get($gm-size, 3);
      padding-bottom: map-get($gm-size, 3);
    }
  }

  &-4 {
    &a {
      padding: map-get($gm-size, 4);
    }
    &t {
      padding-top: map-get($gm-size, 4);
    }
    &b {
      padding-bottom: map-get($gm-size, 4);
    }
    &l {
      padding-left: map-get($gm-size, 4);
    }
    &r {
      padding-right: map-get($gm-size, 4);
    }
    &x {
      padding-left: map-get($gm-size, 4);
      padding-right: map-get($gm-size, 4);
    }
    &y {
      padding-top: map-get($gm-size, 4);
      padding-bottom: map-get($gm-size, 4);
    }
  }

  &-5 {
    &a {
      padding: map-get($gm-size, 5);
    }
    &t {
      padding-top: map-get($gm-size, 5);
    }
    &b {
      padding-bottom: map-get($gm-size, 5);
    }
    &l {
      padding-left: map-get($gm-size, 5);
    }
    &r {
      padding-right: map-get($gm-size, 5);
    }
    &x {
      padding-left: map-get($gm-size, 5);
      padding-right: map-get($gm-size, 5);
    }
    &y {
      padding-top: map-get($gm-size, 5);
      padding-bottom: map-get($gm-size, 5);
    }
  }

  &-6 {
    &a {
      padding: map-get($gm-size, 6);
    }
    &t {
      padding-top: map-get($gm-size, 6);
    }
    &b {
      padding-bottom: map-get($gm-size, 6);
    }
    &l {
      padding-left: map-get($gm-size, 6);
    }
    &r {
      padding-right: map-get($gm-size, 6);
    }
    &x {
      padding-left: map-get($gm-size, 6);
      padding-right: map-get($gm-size, 6);
    }
    &y {
      padding-top: map-get($gm-size, 6);
      padding-bottom: map-get($gm-size, 6);
    }
  }

  &-7 {
    &a {
      padding: map-get($gm-size, 7);
    }
    &t {
      padding-top: map-get($gm-size, 7);
    }
    &b {
      padding-bottom: map-get($gm-size, 7);
    }
    &l {
      padding-left: map-get($gm-size, 7);
    }
    &r {
      padding-right: map-get($gm-size, 7);
    }
    &x {
      padding-left: map-get($gm-size, 7);
      padding-right: map-get($gm-size, 7);
    }
    &y {
      padding-top: map-get($gm-size, 7);
      padding-bottom: map-get($gm-size, 7);
    }
  }

  &-8 {
    &a {
      padding: map-get($gm-size, 8);
    }
    &t {
      padding-top: map-get($gm-size, 8);
    }
    &b {
      padding-bottom: map-get($gm-size, 8);
    }
    &l {
      padding-left: map-get($gm-size, 8);
    }
    &r {
      padding-right: map-get($gm-size, 8);
    }
    &x {
      padding-left: map-get($gm-size, 8);
      padding-right: map-get($gm-size, 8);
    }
    &y {
      padding-top: map-get($gm-size, 8);
      padding-bottom: map-get($gm-size, 8);
    }
  }

  &-9 {
    &a {
      padding: map-get($gm-size, 9);
    }
    &t {
      padding-top: map-get($gm-size, 9);
    }
    &b {
      padding-bottom: map-get($gm-size, 9);
    }
    &l {
      padding-left: map-get($gm-size, 9);
    }
    &r {
      padding-right: map-get($gm-size, 9);
    }
    &x {
      padding-left: map-get($gm-size, 9);
      padding-right: map-get($gm-size, 9);
    }
    &y {
      padding-top: map-get($gm-size, 9);
      padding-bottom: map-get($gm-size, 9);
    }
  }

  &-10 {
    &a {
      padding: map-get($gm-size, 10);
    }
    &t {
      padding-top: map-get($gm-size, 10);
    }
    &b {
      padding-bottom: map-get($gm-size, 10);
    }
    &l {
      padding-left: map-get($gm-size, 10);
    }
    &r {
      padding-right: map-get($gm-size, 10);
    }
    &x {
      padding-left: map-get($gm-size, 10);
      padding-right: map-get($gm-size, 10);
    }
    &y {
      padding-top: map-get($gm-size, 10);
      padding-bottom: map-get($gm-size, 10);
    }
  }

  &-11 {
    &a {
      padding: map-get($gm-size, 11);
    }
    &t {
      padding-top: map-get($gm-size, 11);
    }
    &b {
      padding-bottom: map-get($gm-size, 11);
    }
    &l {
      padding-left: map-get($gm-size, 11);
    }
    &r {
      padding-right: map-get($gm-size, 11);
    }
    &x {
      padding-left: map-get($gm-size, 11);
      padding-right: map-get($gm-size, 11);
    }
    &y {
      padding-top: map-get($gm-size, 11);
      padding-bottom: map-get($gm-size, 11);
    }
  }

  &-12 {
    &a {
      padding: map-get($gm-size, 12);
    }
    &t {
      padding-top: map-get($gm-size, 12);
    }
    &b {
      padding-bottom: map-get($gm-size, 12);
    }
    &l {
      padding-left: map-get($gm-size, 12);
    }
    &r {
      padding-right: map-get($gm-size, 12);
    }
    &x {
      padding-left: map-get($gm-size, 12);
      padding-right: map-get($gm-size, 12);
    }
    &y {
      padding-top: map-get($gm-size, 12);
      padding-bottom: map-get($gm-size, 12);
    }
  }

  &-13 {
    &a {
      padding: map-get($gm-size, 13);
    }
    &t {
      padding-top: map-get($gm-size, 13);
    }
    &b {
      padding-bottom: map-get($gm-size, 13);
    }
    &l {
      padding-left: map-get($gm-size, 13);
    }
    &r {
      padding-right: map-get($gm-size, 13);
    }
    &x {
      padding-left: map-get($gm-size, 13);
      padding-right: map-get($gm-size, 13);
    }
    &y {
      padding-top: map-get($gm-size, 13);
      padding-bottom: map-get($gm-size, 13);
    }
  }

  &-14 {
    &a {
      padding: map-get($gm-size, 14);
    }
    &t {
      padding-top: map-get($gm-size, 14);
    }
    &b {
      padding-bottom: map-get($gm-size, 14);
    }
    &l {
      padding-left: map-get($gm-size, 14);
    }
    &r {
      padding-right: map-get($gm-size, 14);
    }
    &x {
      padding-left: map-get($gm-size, 14);
      padding-right: map-get($gm-size, 14);
    }
    &y {
      padding-top: map-get($gm-size, 14);
      padding-bottom: map-get($gm-size, 14);
    }
  }

  &-15 {
    &a {
      padding: map-get($gm-size, 15);
    }
    &t {
      padding-top: map-get($gm-size, 15);
    }
    &b {
      padding-bottom: map-get($gm-size, 15);
    }
    &l {
      padding-left: map-get($gm-size, 15);
    }
    &r {
      padding-right: map-get($gm-size, 15);
    }
    &x {
      padding-left: map-get($gm-size, 15);
      padding-right: map-get($gm-size, 15);
    }
    &y {
      padding-top: map-get($gm-size, 15);
      padding-bottom: map-get($gm-size, 15);
    }
  }

  &-16 {
    &a {
      padding: map-get($gm-size, 16);
    }
    &t {
      padding-top: map-get($gm-size, 16);
    }
    &b {
      padding-bottom: map-get($gm-size, 16);
    }
    &l {
      padding-left: map-get($gm-size, 16);
    }
    &r {
      padding-right: map-get($gm-size, 16);
    }
    &x {
      padding-left: map-get($gm-size, 16);
      padding-right: map-get($gm-size, 16);
    }
    &y {
      padding-top: map-get($gm-size, 16);
      padding-bottom: map-get($gm-size, 16);
    }
  }

  &-17 {
    &a {
      padding: map-get($gm-size, 17);
    }
    &t {
      padding-top: map-get($gm-size, 17);
    }
    &b {
      padding-bottom: map-get($gm-size, 17);
    }
    &l {
      padding-left: map-get($gm-size, 17);
    }
    &r {
      padding-right: map-get($gm-size, 17);
    }
    &x {
      padding-left: map-get($gm-size, 17);
      padding-right: map-get($gm-size, 17);
    }
    &y {
      padding-top: map-get($gm-size, 17);
      padding-bottom: map-get($gm-size, 17);
    }
  }

  // no padding per level
  &-none {
    &-1 {
      & > * {
        padding: 0 !important;
      }
    }
    &-2 {
      & > * {
        padding: 0 !important;
        & > * {
          padding: 0 !important;
        }
      }
    }
  }
}
