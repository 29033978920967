@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// $
/* Scrollbar Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $primary-200 transparent;
}

*::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: $primary-200;
}

*::-webkit-scrollbar-thumb,
.q-scrollarea__thumb {
  border-radius: 8px;
  background-color: rgba($primary, 0.25);
}

.overflow-view {
  margin: -8px !important;
  &-left {
    margin-left: -8px !important;
    & .overflow-view {
      margin-left: 0 !important;
    }
  }
  &-right {
    margin-right: -8px !important;
    & .overflow-view {
      margin-right: 0 !important;
    }
  }
  &-top {
    margin-top: -8px !important;
    & .overflow-view {
      margin-top: 0 !important;
    }
  }
  &-bottom {
    margin-bottom: -8px !important;
    & .overflow-view {
      margin-bottom: 0 !important;
    }
  }
  &-y {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
    & .overflow-view {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  &-x {
    margin-left: -8px !important;
    margin-right: -8px !important;
    & .overflow-view {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  & .overflow-view {
    margin: 0 !important;
  }
}

.overflow-view {
  &-left {
    & > * {
      padding-left: 8px !important;
    }
  }
  &-right {
    & > * {
      padding-right: 8px !important;
    }
  }
  &-top {
    & > * {
      padding-top: 8px !important;
    }
  }
  &-bottom {
    & > * {
      padding-bottom: 8px !important;
    }
  }
  &-y {
    & > * {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }
  }
  &-x {
    & > * {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
  & > * {
    padding: 8px !important;
  }
}

/* Scrollbar Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.q-scrollarea {
  &__thumb,
  &__bar {
    &--h {
      height: 8px;
    }
    &--v {
      width: 8px;
    }
  }
}
