@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.q-notification {
  & .q-notification {
    &__caption {
      font-size: map-get($body2, 'size');
      letter-spacing: map-get($body2, 'letter-spacing');
    }
  }
}

.q-notifications {
  &__list {
    & .notif-ui {
      min-height: 56px !important;
      & .q-notification__message {
        padding: 0;
      }
    }
    &--bottom {
      & .notif-ui {
        margin-bottom: 60px;
        margin-top: -40px;
      }
    }
    &--top {
      & .notif-ui {
        margin-top: 40px;
        margin-bottom: -20px;
      }
    }
  }
}
