@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.tooltip-pro {
  background-color: transparent !important;
}
.tooltip {
  overflow: visible;
  border-radius: $border;
  &-text {
    z-index: 10;
    padding: 5px 10px;
    border-radius: $border;
    font-weight: $font-medium;
  }
  &-arrow {
    z-index: 5;
    width: 20px;
    height: 20px;
    transform: translateX(-50%) rotate(-45deg) skew(-20deg, -20deg);

    // top arrows
    [class*='tooltip-arrow-top'] & {
      top: 0px;
    }
    .tooltip-arrow-top & {
      left: 50%;
    }
    .tooltip-arrow-top-left & {
      left: 20px;
    }
    .tooltip-arrow-top-right & {
      right: 0px;
    }

    // bottom arrows
    [class*='tooltip-arrow-bottom'] & {
      bottom: 0px;
    }
    .tooltip-arrow-bottom & {
      left: 50%;
    }
    .tooltip-arrow-bottom-left & {
      left: 20px;
    }
    .tooltip-arrow-bottom-right & {
      right: 0px;
    }

    // left arrows
    [class*='tooltip-arrow-left'] & {
      transform: translateY(-50%) rotate(45deg) skew(-20deg, -20deg);
      left: 0px;
    }
    .tooltip-arrow-left & {
      top: 50%;
    }
    .tooltip-arrow-left-top & {
      top: 20px;
    }
    .tooltip-arrow-left-bottom & {
      bottom: -10px;
    }

    // right arrows
    [class*='tooltip-arrow-right'] & {
      transform: translateY(-50%) rotate(45deg) skew(-20deg, -20deg);
      right: 0px;
    }
    .tooltip-arrow-right & {
      top: 50%;
    }
    .tooltip-arrow-right-top & {
      top: 20px;
    }
    .tooltip-arrow-right-bottom & {
      bottom: -10px;
    }
  }

  // close button
  &-close {
    pointer-events: all !important;
  }
}
