@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.q-date__calendar-item > div,
.q-date__calendar-item button {
  font-size: map-get($gm-buttons-sm, 'size');
}

.q-btn {
  // default button size
  font-size: map-get($gm-buttons-md, 'size');

  &-group {
    font-family: 'TTNormsPro-Md';
    font-weight: $font-medium;
    min-height: $button-group-min-height;
    min-width: $button-group-min-height;
    & > button {
      color: $primary-600;
      min-height: calc(#{$button-group-min-height} - 2px);
      min-width: calc(#{$button-group-min-height} - 2px);
    }
  }
  &--outline {
    &.light-outline {
      &::before {
        border-color: $border-color;
      }
    }
  }
  &:hover {
    &.hover-border {
      &-primary {
        &:before {
          border-color: $primary;
        }
      }
      &-secondry {
        &:before {
          border-color: $secondary;
        }
      }
      &-accent {
        &:before {
          border-color: $accent;
        }
      }
    }
  }

  &.button {
    &-xs {
      height: auto;
      min-height: map-get($gm-buttons-xs, 'height');
      min-width: map-get($gm-buttons-xs, 'height');
      font-size: map-get($gm-buttons-xs, 'size');
      &:not(.q-btn--round, .no-btn-padding, [class*='q-p']) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    &-sm {
      height: auto;
      min-height: map-get($gm-buttons-sm, 'height');
      min-width: map-get($gm-buttons-sm, 'height');
      font-size: map-get($gm-buttons-sm, 'size');
      &:not(.q-btn--round, .no-btn-padding, [class*='q-p']) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    &-md {
      height: auto;
      min-height: map-get($gm-buttons-md, 'height');
      min-width: map-get($gm-buttons-md, 'height');
      font-size: map-get($gm-buttons-md, 'size');
      &:not(.q-btn--round, .no-btn-padding, [class*='q-p']) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
    &-lg {
      height: auto;
      min-height: map-get($gm-buttons-lg, 'height');
      min-width: map-get($gm-buttons-lg, 'height');
      font-size: map-get($gm-buttons-lg, 'size');
      &:not(.q-btn--round, .no-btn-padding, [class*='q-p']) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
    &-xl {
      height: auto;
      min-height: map-get($gm-buttons-xl, 'height');
      min-width: map-get($gm-buttons-xl, 'height');
      font-size: map-get($gm-buttons-xl, 'size');
      &:not(.q-btn--round, .no-btn-padding, [class*='q-p']) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}

// custom class
.button {
  &-gm {
    &-outline {
      &-dark {
        color: $primary-700 !important;
        transition: all $animate-duration;
        &::before {
          border-color: $primary-200;
          box-shadow: $shadow-field;
        }
        & .q-focus-helper {
          background: white !important;
        }
        &:hover {
          &::before {
            border-color: $primary-400;
          }
        }
      }
    }
    &-highlight {
      &:not(.q-btn--flat, .not-gm, .disabled) {
        &:before {
          transition: $shadow-transition;
        }
        &:active,
        &:focus-within {
          &:before {
            box-shadow: map-get($gm-shadows, 'active');
          }
          & .q-focus-helper {
            opacity: 0 !important;
          }
        }
      }
    }
  }

  // deprecated
  &-hover {
    &:hover {
      & .q-btn {
        &:not(.q-btn--flat) {
          &:before {
            transition: $shadow-transition;
          }
          &:before {
            box-shadow: $shadow-gm;
          }
          & .q-focus-helper {
            opacity: 0 !important;
          }
        }
      }
    }
  }
}
