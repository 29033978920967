@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.input {
  &-white {
    & .q-field__inner {
      background-color: white;
    }
  }
  &-split {
    &.q-field {
      &.format-bold {
        & .q-field {
          &__control {
            &-container {
              &:before {
                border-width: map-get($borders, 'md');
                border-color: $primary;
              }
            }
          }
        }
      }
      & .q-field {
        &__control {
          padding-right: 0;
          &:before,
          &:after {
            border-color: $border-color;
          }
          &-container {
            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              pointer-events: none;
              border: 1px solid $border-color;
              border-radius: 0 $border $border 0;
              z-index: 2;
            }
            padding: 0 12px 0 6px;
            border-radius: 0 $border $border 0;
            z-index: 1;
          }
        }
        &__prepend {
          padding-right: 0;
        }
      }
      &--focused,
      &--highlighted,
      &:active,
      &:focus-visible,
      &:focus-within,
      &:focus-within {
        &:not(.q-field--standard, .q-field--error) {
          & .q-field__control-container {
            box-shadow: map-get($gm-shadows, 'active');
          }
        }
      }
      &--focused,
      &--highlighted,
      &:hover,
      &:active,
      &:focus-visible,
      &:focus-within,
      &:focus-within {
        & .q-field {
          &__control {
            &-container {
              &:before {
                border-color: $secondary;
              }
            }
          }
        }
      }
      &--error {
        &.q-field {
          &--focused,
          &--highlighted,
          &:active,
          &:focus-visible,
          &:focus-within,
          &:focus-within {
            &:not(.q-field--standard) {
              & .q-field__control-container {
                box-shadow: map-get($gm-shadows, 'error');
                clip-path: inset(
                  -#{$shadow-state-size} 0px -#{$shadow-state-size} -#{$shadow-state-size}
                );
              }
            }
          }
          &--focused,
          &--highlighted,
          &:hover,
          &:active,
          &:focus-visible,
          &:focus-within,
          &:focus-within {
            & .q-field {
              &__control {
                &-container {
                  border-radius: 0;
                  &:before {
                    border-color: $negative;
                    border-right: 0;
                    border-radius: 0;
                  }
                }
              }
            }
          }
        }
      }

      // The split appended style
      &.q-field {
        &--focused,
        &--highlighted,
        &:active,
        &:focus-visible,
        &:focus-within,
        &:focus-within {
          &:not(.q-field--standard, .q-field--error) {
            & .q-field__append {
              &::before {
                box-shadow: map-get($gm-shadows, 'active');
              }
            }
          }
        }

        &--focused,
        &--highlighted,
        &:hover,
        &:active,
        &:focus-visible,
        &:focus-within,
        &:focus-within {
          &:not(.q-field--error) {
            & .q-field__append {
              &::before {
                border-color: $secondary;
              }
            }
          }
        }

        &.format-bold {
          .q-field__append {
            &:before {
              border-width: map-get($borders, 'md');
              border-color: $primary;
            }
          }
        }

        .q-field__append {
          position: relative;
          padding: 0 10px;
          & > * {
            z-index: 9;
          }
          &:before {
            content: '';
            margin-left: -10px;
            background: white;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            border: 1px solid $border-color;
            border-radius: 0 $border $border 0;
            z-index: 3;
            clip-path: inset(
              -#{$shadow-state-size} -#{$shadow-state-size} -#{$shadow-state-size}
                0
            );
            border-left: 0;
          }
          &:first-child {
            &::before {
              margin-left: auto !important;
            }
          }
          &:not(:first-child) {
            padding-left: 5px;
          }
          &:not(:last-child) {
            padding-right: 5px;
            &:before {
              border-radius: 0 0 0 0;
              clip-path: inset(
                -#{$shadow-state-size} 0 -#{$shadow-state-size} 0
              );
              border-right: 0;
            }
          }
        }

        &--error {
          .q-field__append {
            &:before {
              border-color: $negative;
              box-shadow: map-get($gm-shadows, 'error');
            }
          }
        }
      }
    }
  }
}
