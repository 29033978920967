.slide {
  &-hover {
    &-left,
    &-right,
    &-top,
    &-bottom {
      transition: transform 0.3s;
      font-size: 24px;
      &-sm,
      &-md,
      &-lg,
      &-xl {
        transition: transform 0.3s;
        font-size: 24px;
      }
    }
    &-up,
    &-bottom {
      &-left,
      &-right {
        transition: transform 0.3s;
        font-size: 24px;
        &-sm,
        &-md,
        &-lg,
        &-xl {
          transition: transform 0.3s;
          font-size: 24px;
        }
      }
    }
  }
  &-hover:hover {
    & .slide-hover {
      &-up {
        &-left {
          transform-style: preserve-3d;
          transform: translate3d(-8px, -8px, 8px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(0deg);
        }
        &-right {
          transform-style: preserve-3d;
          transform: translate3d(8px, -8px, 8px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(0deg);
        }
      }
      &-bottom {
        &-left {
          transform-style: preserve-3d;
          transform: translate3d(-8px, 8px, 8px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(0deg);
        }
        &-right {
          transform-style: preserve-3d;
          transform: translate3d(8px, 8px, -8px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(0deg);
        }
      }
      &-left {
        transform: translateX(20px);
        &-sm {
          transform: translateX(5px);
        }
        &-md {
          transform: translateX(10px);
        }
        &-lg {
          transform: translateX(15px);
        }
        &-xl {
          transform: translateX(25px);
        }
      }
      &-right {
        transform: translateX(-20px);
        &-sm {
          transform: translateX(-5px);
        }
        &-md {
          transform: translateX(-10px);
        }
        &-lg {
          transform: translateX(-15px);
        }
        &-xl {
          transform: translateX(-25px);
        }
      }
      &-top {
        transform: translateY(-20px);
        &-sm {
          transform: translateY(-5px);
        }
        &-md {
          transform: translateY(-10px);
        }
        &-lg {
          transform: translateY(-15px);
        }
        &-xl {
          transform: translateY(-25px);
        }
      }
      &-bottom {
        transform: translateY(20px);
        &-sm {
          transform: translateY(5px);
        }
        &-md {
          transform: translateY(10px);
        }
        &-lg {
          transform: translateY(15px);
        }
        &-xl {
          transform: translateY(25px);
        }
      }
    }
  }
}
