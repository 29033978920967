@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.shadows {
  &-sm {
    box-shadow: map-get($gm-shadows, 'sm');
  }
  &-md {
    box-shadow: map-get($gm-shadows, 'md');
  }
  &-lg {
    box-shadow: map-get($gm-shadows, 'lg');
  }
  &-gm {
    box-shadow: $shadow-gm !important;
  }
  &-active {
    box-shadow: map-get($gm-shadows, 'active');
  }
}
