@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.bg {
  &-translucent {
    background-color: rgba(white, $translucent);
    &-dark {
      background-color: rgba(grey, $translucent);
    }
    &-light {
      background-color: rgba(white, $translucent);
    }
  }

  &-dark,
  &-dark:hover {
    background-color: $dark;
    & .q-focus-helper {
      background-color: $dark;
      &:before,
      &:after {
        background-color: $dark;
      }
    }
    &-secondary,
    &-secondary:hover {
      background-color: $dark-secondary;
      & .q-focus-helper {
        background-color: $dark-secondary;
        &:before,
        &:after {
          background-color: $dark-secondary;
        }
      }
    }
  }

  &-none,
  &-none:hover {
    background-color: transparent !important;
    & .q-focus-helper {
      background-color: transparent !important;
      &::before,
      &::after {
        background-color: transparent !important;
      }
    }
  }
  &-accent,
  &-accent:hover {
    background-color: $accent;
    & .q-focus-helper {
      background-color: $accent;
      &:before,
      &:after {
        background-color: $accent;
      }
    }
  }
  &-accent2,
  &-accent2:hover {
    background-color: $accent2;
    & .q-focus-helper {
      background-color: $accent2;
      &:before,
      &:after {
        background-color: $accent2;
      }
    }
  }
  &-color,
  &-color:hover {
    background-color: $accent2 !important;
    & .q-focus-helper {
      background-color: $accent2;
      &:before,
      &:after {
        background-color: $accent2;
      }
    }
  }

  &-gradient {
    background-image: linear-gradient(transparent, white);
    &-color {
      background-image: linear-gradient(transparent, $accent2);
    }
    &-right {
      background-image: linear-gradient(to right, transparent, white);
      &-color {
        background-image: linear-gradient(to right, transparent, $accent2);
      }
    }
    &-left {
      background-image: linear-gradient(to left, transparent, white);
      &-color {
        background-image: linear-gradient(to left, transparent, $accent2);
      }
    }
  }

  &-primary {
    &-1 {
      background-color: $primary-1;
    }
    &-50 {
      background-color: $primary-50;
    }
    &-100 {
      background-color: $primary-100;
    }
    &-200 {
      background-color: $primary-200;
    }
    &-300 {
      background-color: $primary-300;
    }
    &-400 {
      background-color: $primary-400;
    }
    &-500 {
      background-color: $primary-500;
    }
    &-600 {
      background-color: $primary-600;
    }
    &-700 {
      background-color: $primary-700;
    }
    &-800 {
      background-color: $primary-800;
    }
    &-900 {
      background-color: $primary-900;
    }
  }
  &-secondary {
    &-50 {
      background-color: $secondary-50;
    }
    &-100 {
      background-color: $secondary-100;
    }
    &-200 {
      background-color: $secondary-200;
    }
    &-300 {
      background-color: $secondary-300;
    }
    &-400 {
      background-color: $secondary-400;
    }
    &-500 {
      background-color: $secondary-500;
    }
    &-600 {
      background-color: $secondary-600;
    }
    &-700 {
      background-color: $secondary-700;
    }
    &-800 {
      background-color: $secondary-800;
    }
    &-900 {
      background-color: $secondary-900;
    }
  }
  &-warning {
    &-50 {
      background-color: $warning-50;
    }
    &-100 {
      background-color: $warning-100;
    }
    &-200 {
      background-color: $warning-200;
    }
    &-300 {
      background-color: $warning-300;
    }
    &-400 {
      background-color: $warning-400;
    }
    &-500 {
      background-color: $warning-500;
    }
    &-600 {
      background-color: $warning-600;
    }
    &-700 {
      background-color: $warning-700;
    }
    &-800 {
      background-color: $warning-800;
    }
    &-900 {
      background-color: $warning-900;
    }
  }
  &-negative {
    &-50 {
      background-color: $negative-50;
    }
    &-100 {
      background-color: $negative-100;
    }
    &-200 {
      background-color: $negative-200;
    }
    &-300 {
      background-color: $negative-300;
    }
    &-400 {
      background-color: $negative-400;
    }
    &-500 {
      background-color: $negative-500;
    }
    &-600 {
      background-color: $negative-600;
    }
    &-700 {
      background-color: $negative-700;
    }
    &-800 {
      background-color: $negative-800;
    }
    &-900 {
      background-color: $negative-900;
    }
  }
  &-positive {
    &-50 {
      background-color: $positive-50;
    }
    &-100 {
      background-color: $positive-100;
    }
    &-200 {
      background-color: $positive-200;
    }
    &-300 {
      background-color: $positive-300;
    }
    &-400 {
      background-color: $positive-400;
    }
    &-500 {
      background-color: $positive-500;
    }
    &-600 {
      background-color: $positive-600;
    }
    &-700 {
      background-color: $positive-700;
    }
    &-800 {
      background-color: $positive-800;
    }
    &-900 {
      background-color: $positive-900;
    }
  }
  &-logo {
    &1 {
      background-color: map-get($gm-logo, 'color-1');
    }
    &2 {
      background-color: map-get($gm-logo, 'color-2');
    }
    &3 {
      background-color: map-get($gm-logo, 'color-3');
    }
  }
}
