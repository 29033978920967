@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $placeholder-color !important;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $placeholder-color !important;
  opacity: 1 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $placeholder-color !important;
  opacity: 1 !important;
}

/* Placeholder color */
.text-placeholder {
  color: $placeholder-color;
  opacity: 1 !important;
}

/* Editor placeholder */
.q-editor .q-editor__content:empty:not(:focus)::before {
  color: $placeholder-color;
  opacity: 1 !important;
}
