@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
/**
* Growmodo, GmbH
* Growmodo Hub - Custom Icon Library
* Copywrite: Mike Saraus (https://github.com/mikesaraus)
*/

$GrowmodoIconFont-font: "GrowmodoIconFont";

@font-face {
  font-family: $GrowmodoIconFont-font;
  src: url('~assets/fonts/icons/GrowmodoIconFont.woff2') format('woff2');
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: GrowmodoIconFont !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 1;
  font-variant: normal;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  shape-rendering: crispEdges;
  font-feature-settings: "liga";
}

$GrowmodoIconFont-map: (
  "3-balls": "\f101",
  "alert-circle": "\f102",
  "alert-octagon": "\f103",
  "alert-triangle": "\f104",
  "align-bottom-01": "\f105",
  "align-center": "\f106",
  "align-justify": "\f107",
  "align-left": "\f108",
  "align-right": "\f109",
  "align-top-arrow-01": "\f10a",
  "all-categories": "\f10b",
  "annotation-check": "\f10c",
  "archive": "\f10d",
  "arrow-circle-broken-down": "\f10e",
  "arrow-circle-broken-up": "\f10f",
  "arrow-narrow-down": "\f110",
  "arrow-narrow-left": "\f111",
  "arrow-narrow-right": "\f112",
  "arrow-narrow-up-right": "\f113",
  "arrow-narrow-up": "\f114",
  "arrows-right": "\f115",
  "attachment-02": "\f116",
  "bar-chart-square-01": "\f117",
  "bell-01": "\f118",
  "bold-01": "\f119",
  "book-cover": "\f11a",
  "brackets-plus": "\f11b",
  "brand-guidelines": "\f11c",
  "briefcase-01": "\f11d",
  "briefcase-02": "\f11e",
  "browser": "\f11f",
  "building-05": "\f120",
  "calculator": "\f121",
  "calendar": "\f122",
  "cancel": "\f123",
  "check-circle-broken": "\f124",
  "check-circle": "\f125",
  "check-square": "\f126",
  "check": "\f127",
  "chevron-down": "\f128",
  "chevron-left-double": "\f129",
  "chevron-left": "\f12a",
  "chevron-right-double": "\f12b",
  "chevron-right": "\f12c",
  "chevron-up": "\f12d",
  "circle-01": "\f12e",
  "circle-active": "\f12f",
  "circle-inactive": "\f130",
  "circle": "\f131",
  "clapperboard": "\f132",
  "clear-all": "\f133",
  "clock": "\f134",
  "code-02": "\f135",
  "code-browser": "\f136",
  "code-snippet-02": "\f137",
  "code-square-01": "\f138",
  "colors": "\f139",
  "compass-03": "\f13a",
  "copy-06": "\f13b",
  "corner-down-left": "\f13c",
  "corner-down-right": "\f13d",
  "credit-card-02": "\f13e",
  "cube-01": "\f13f",
  "data-enrichment": "\f140",
  "dataflow-01": "\f141",
  "dataflow-03": "\f142",
  "dataflow-04": "\f143",
  "done-all": "\f144",
  "dots-horizontal": "\f145",
  "dots-vertical": "\f146",
  "download-02": "\f147",
  "download-04": "\f148",
  "download-cloud-01": "\f149",
  "dropper": "\f14a",
  "edit-01": "\f14b",
  "edit-03": "\f14c",
  "edit-05": "\f14d",
  "eye-off": "\f14e",
  "eye": "\f14f",
  "face-sad": "\f150",
  "file-06": "\f151",
  "file-attachment-04": "\f152",
  "file-check-03": "\f153",
  "file-download-01": "\f154",
  "filter-funnel-01": "\f155",
  "filter-funnel-02": "\f156",
  "filter-lines": "\f157",
  "flag-01": "\f158",
  "flyer-brochure": "\f159",
  "folder-black": "\f15a",
  "folder-plus": "\f15b",
  "folder": "\f15c",
  "format-clear": "\f15d",
  "fullscreen": "\f15e",
  "gaming-pad-01": "\f15f",
  "gift-01": "\f160",
  "gradient": "\f161",
  "grid-01": "\f162",
  "growmodo-colored": "\f163",
  "growmodo-d": "\f164",
  "growmodo-g": "\f165",
  "growmodo-m": "\f166",
  "growmodo-o": "\f167",
  "growmodo-r": "\f168",
  "growmodo-w": "\f169",
  "growmodo": "\f16a",
  "heading-01": "\f16b",
  "home-05": "\f16c",
  "home-line": "\f16d",
  "home-smile": "\f16e",
  "image-03": "\f16f",
  "image-04": "\f170",
  "image-indent-left": "\f171",
  "image-plus": "\f172",
  "infinity": "\f173",
  "info-circle": "\f174",
  "italic-01": "\f175",
  "italic-square": "\f176",
  "layers-three-01": "\f177",
  "layout-alt-02": "\f178",
  "layout-alt-04": "\f179",
  "left-indent-01": "\f17a",
  "lightning-quick": "\f17b",
  "line-chart-up-03": "\f17c",
  "link-01": "\f17d",
  "link-broken-01": "\f17e",
  "link-external-01": "\f17f",
  "list-numbered": "\f180",
  "list": "\f181",
  "loading-01": "\f182",
  "lock-01": "\f183",
  "lock-04": "\f184",
  "log-out-01": "\f185",
  "magic-wand-02": "\f186",
  "mail-02": "\f187",
  "maximize-02": "\f188",
  "message-chat-square": "\f189",
  "message-check-square": "\f18a",
  "message-dots-square": "\f18b",
  "message-heart-square": "\f18c",
  "message-question-square": "\f18d",
  "message-smile-square": "\f18e",
  "message-square-02": "\f18f",
  "minus": "\f190",
  "monitor-03": "\f191",
  "motion-graphic": "\f192",
  "online-course": "\f193",
  "packaging-design": "\f194",
  "palette": "\f195",
  "paper-plane-2": "\f196",
  "passcode-lock": "\f197",
  "pause-circle": "\f198",
  "pen-tool-02": "\f199",
  "pencil-02": "\f19a",
  "pending": "\f19b",
  "percent-03": "\f19c",
  "phone-02": "\f19d",
  "phone-call-01": "\f19e",
  "play-solid": "\f19f",
  "play": "\f1a0",
  "plus-square": "\f1a1",
  "plus": "\f1a2",
  "presentation-chart-01": "\f1a3",
  "printer": "\f1a4",
  "puzzle-piece-02": "\f1a5",
  "puzzle-piece": "\f1a6",
  "quote": "\f1a7",
  "refresh-ccw-01": "\f1a8",
  "refresh-ccw-03": "\f1a9",
  "refresh-cw-01": "\f1aa",
  "reverse-left": "\f1ab",
  "reverse-right": "\f1ac",
  "right-indent-01": "\f1ad",
  "rows-03": "\f1ae",
  "sale-02": "\f1af",
  "save-01": "\f1b0",
  "scale-01": "\f1b1",
  "search-lg": "\f1b2",
  "search-sm": "\f1b3",
  "send-03": "\f1b4",
  "server-01": "\f1b5",
  "server-05": "\f1b6",
  "settings-01": "\f1b7",
  "settings-02": "\f1b8",
  "shield-tick": "\f1b9",
  "shopping-cart-03": "\f1ba",
  "social-angellist": "\f1bb",
  "social-apple": "\f1bc",
  "social-clubhouse": "\f1bd",
  "social-discord": "\f1be",
  "social-dribbble": "\f1bf",
  "social-facebook": "\f1c0",
  "social-figma": "\f1c1",
  "social-github": "\f1c2",
  "social-google": "\f1c3",
  "social-instagram": "\f1c4",
  "social-linkedin": "\f1c5",
  "social-pinterest": "\f1c6",
  "social-reddit": "\f1c7",
  "social-signal": "\f1c8",
  "social-snapchat": "\f1c9",
  "social-telegram": "\f1ca",
  "social-tiktok": "\f1cb",
  "social-tumblr": "\f1cc",
  "social-twitter": "\f1cd",
  "social-youtube": "\f1ce",
  "speedometer-02": "\f1cf",
  "square-active": "\f1d0",
  "square-inactive": "\f1d1",
  "square": "\f1d2",
  "star-01": "\f1d3",
  "star-asterisk": "\f1d4",
  "star-fill": "\f1d5",
  "strikethrough-01": "\f1d6",
  "style": "\f1d7",
  "tag": "\f1d8",
  "terminal-browser": "\f1d9",
  "tick": "\f1da",
  "tool-02": "\f1db",
  "trash-01": "\f1dc",
  "triangle-down": "\f1dd",
  "tune": "\f1de",
  "type-01": "\f1df",
  "type-02": "\f1e0",
  "type-square": "\f1e1",
  "underline-01": "\f1e2",
  "upload-01": "\f1e3",
  "upload-04": "\f1e4",
  "upload-cloud-01": "\f1e5",
  "user-03": "\f1e6",
  "user-plus-01": "\f1e7",
  "users-01": "\f1e8",
  "users-02": "\f1e9",
  "users-plus": "\f1ea",
  "video-recorder": "\f1eb",
  "wallet-02": "\f1ec",
  "wifi-off": "\f1ed",
  "workflow-setup": "\f1ee",
  "x-circle": "\f1ef",
  "x-close": "\f1f0",
  "zoom-in": "\f1f1",
  "zoom-out": "\f1f2",
);

.icon {
  &-3-balls:before {
    content: map-get($GrowmodoIconFont-map, "3-balls");
  }
  &-alert-circle:before {
    content: map-get($GrowmodoIconFont-map, "alert-circle");
  }
  &-alert-octagon:before {
    content: map-get($GrowmodoIconFont-map, "alert-octagon");
  }
  &-alert-triangle:before {
    content: map-get($GrowmodoIconFont-map, "alert-triangle");
  }
  &-align-bottom-01:before {
    content: map-get($GrowmodoIconFont-map, "align-bottom-01");
  }
  &-align-center:before {
    content: map-get($GrowmodoIconFont-map, "align-center");
  }
  &-align-justify:before {
    content: map-get($GrowmodoIconFont-map, "align-justify");
  }
  &-align-left:before {
    content: map-get($GrowmodoIconFont-map, "align-left");
  }
  &-align-right:before {
    content: map-get($GrowmodoIconFont-map, "align-right");
  }
  &-align-top-arrow-01:before {
    content: map-get($GrowmodoIconFont-map, "align-top-arrow-01");
  }
  &-all-categories:before {
    content: map-get($GrowmodoIconFont-map, "all-categories");
  }
  &-annotation-check:before {
    content: map-get($GrowmodoIconFont-map, "annotation-check");
  }
  &-archive:before {
    content: map-get($GrowmodoIconFont-map, "archive");
  }
  &-arrow-circle-broken-down:before {
    content: map-get($GrowmodoIconFont-map, "arrow-circle-broken-down");
  }
  &-arrow-circle-broken-up:before {
    content: map-get($GrowmodoIconFont-map, "arrow-circle-broken-up");
  }
  &-arrow-narrow-down:before {
    content: map-get($GrowmodoIconFont-map, "arrow-narrow-down");
  }
  &-arrow-narrow-left:before {
    content: map-get($GrowmodoIconFont-map, "arrow-narrow-left");
  }
  &-arrow-narrow-right:before {
    content: map-get($GrowmodoIconFont-map, "arrow-narrow-right");
  }
  &-arrow-narrow-up-right:before {
    content: map-get($GrowmodoIconFont-map, "arrow-narrow-up-right");
  }
  &-arrow-narrow-up:before {
    content: map-get($GrowmodoIconFont-map, "arrow-narrow-up");
  }
  &-arrows-right:before {
    content: map-get($GrowmodoIconFont-map, "arrows-right");
  }
  &-attachment-02:before {
    content: map-get($GrowmodoIconFont-map, "attachment-02");
  }
  &-bar-chart-square-01:before {
    content: map-get($GrowmodoIconFont-map, "bar-chart-square-01");
  }
  &-bell-01:before {
    content: map-get($GrowmodoIconFont-map, "bell-01");
  }
  &-bold-01:before {
    content: map-get($GrowmodoIconFont-map, "bold-01");
  }
  &-book-cover:before {
    content: map-get($GrowmodoIconFont-map, "book-cover");
  }
  &-brackets-plus:before {
    content: map-get($GrowmodoIconFont-map, "brackets-plus");
  }
  &-brand-guidelines:before {
    content: map-get($GrowmodoIconFont-map, "brand-guidelines");
  }
  &-briefcase-01:before {
    content: map-get($GrowmodoIconFont-map, "briefcase-01");
  }
  &-briefcase-02:before {
    content: map-get($GrowmodoIconFont-map, "briefcase-02");
  }
  &-browser:before {
    content: map-get($GrowmodoIconFont-map, "browser");
  }
  &-building-05:before {
    content: map-get($GrowmodoIconFont-map, "building-05");
  }
  &-calculator:before {
    content: map-get($GrowmodoIconFont-map, "calculator");
  }
  &-calendar:before {
    content: map-get($GrowmodoIconFont-map, "calendar");
  }
  &-cancel:before {
    content: map-get($GrowmodoIconFont-map, "cancel");
  }
  &-check-circle-broken:before {
    content: map-get($GrowmodoIconFont-map, "check-circle-broken");
  }
  &-check-circle:before {
    content: map-get($GrowmodoIconFont-map, "check-circle");
  }
  &-check-square:before {
    content: map-get($GrowmodoIconFont-map, "check-square");
  }
  &-check:before {
    content: map-get($GrowmodoIconFont-map, "check");
  }
  &-chevron-down:before {
    content: map-get($GrowmodoIconFont-map, "chevron-down");
  }
  &-chevron-left-double:before {
    content: map-get($GrowmodoIconFont-map, "chevron-left-double");
  }
  &-chevron-left:before {
    content: map-get($GrowmodoIconFont-map, "chevron-left");
  }
  &-chevron-right-double:before {
    content: map-get($GrowmodoIconFont-map, "chevron-right-double");
  }
  &-chevron-right:before {
    content: map-get($GrowmodoIconFont-map, "chevron-right");
  }
  &-chevron-up:before {
    content: map-get($GrowmodoIconFont-map, "chevron-up");
  }
  &-circle-01:before {
    content: map-get($GrowmodoIconFont-map, "circle-01");
  }
  &-circle-active:before {
    content: map-get($GrowmodoIconFont-map, "circle-active");
  }
  &-circle-inactive:before {
    content: map-get($GrowmodoIconFont-map, "circle-inactive");
  }
  &-circle:before {
    content: map-get($GrowmodoIconFont-map, "circle");
  }
  &-clapperboard:before {
    content: map-get($GrowmodoIconFont-map, "clapperboard");
  }
  &-clear-all:before {
    content: map-get($GrowmodoIconFont-map, "clear-all");
  }
  &-clock:before {
    content: map-get($GrowmodoIconFont-map, "clock");
  }
  &-code-02:before {
    content: map-get($GrowmodoIconFont-map, "code-02");
  }
  &-code-browser:before {
    content: map-get($GrowmodoIconFont-map, "code-browser");
  }
  &-code-snippet-02:before {
    content: map-get($GrowmodoIconFont-map, "code-snippet-02");
  }
  &-code-square-01:before {
    content: map-get($GrowmodoIconFont-map, "code-square-01");
  }
  &-colors:before {
    content: map-get($GrowmodoIconFont-map, "colors");
  }
  &-compass-03:before {
    content: map-get($GrowmodoIconFont-map, "compass-03");
  }
  &-copy-06:before {
    content: map-get($GrowmodoIconFont-map, "copy-06");
  }
  &-corner-down-left:before {
    content: map-get($GrowmodoIconFont-map, "corner-down-left");
  }
  &-corner-down-right:before {
    content: map-get($GrowmodoIconFont-map, "corner-down-right");
  }
  &-credit-card-02:before {
    content: map-get($GrowmodoIconFont-map, "credit-card-02");
  }
  &-cube-01:before {
    content: map-get($GrowmodoIconFont-map, "cube-01");
  }
  &-data-enrichment:before {
    content: map-get($GrowmodoIconFont-map, "data-enrichment");
  }
  &-dataflow-01:before {
    content: map-get($GrowmodoIconFont-map, "dataflow-01");
  }
  &-dataflow-03:before {
    content: map-get($GrowmodoIconFont-map, "dataflow-03");
  }
  &-dataflow-04:before {
    content: map-get($GrowmodoIconFont-map, "dataflow-04");
  }
  &-done-all:before {
    content: map-get($GrowmodoIconFont-map, "done-all");
  }
  &-dots-horizontal:before {
    content: map-get($GrowmodoIconFont-map, "dots-horizontal");
  }
  &-dots-vertical:before {
    content: map-get($GrowmodoIconFont-map, "dots-vertical");
  }
  &-download-02:before {
    content: map-get($GrowmodoIconFont-map, "download-02");
  }
  &-download-04:before {
    content: map-get($GrowmodoIconFont-map, "download-04");
  }
  &-download-cloud-01:before {
    content: map-get($GrowmodoIconFont-map, "download-cloud-01");
  }
  &-dropper:before {
    content: map-get($GrowmodoIconFont-map, "dropper");
  }
  &-edit-01:before {
    content: map-get($GrowmodoIconFont-map, "edit-01");
  }
  &-edit-03:before {
    content: map-get($GrowmodoIconFont-map, "edit-03");
  }
  &-edit-05:before {
    content: map-get($GrowmodoIconFont-map, "edit-05");
  }
  &-eye-off:before {
    content: map-get($GrowmodoIconFont-map, "eye-off");
  }
  &-eye:before {
    content: map-get($GrowmodoIconFont-map, "eye");
  }
  &-face-sad:before {
    content: map-get($GrowmodoIconFont-map, "face-sad");
  }
  &-file-06:before {
    content: map-get($GrowmodoIconFont-map, "file-06");
  }
  &-file-attachment-04:before {
    content: map-get($GrowmodoIconFont-map, "file-attachment-04");
  }
  &-file-check-03:before {
    content: map-get($GrowmodoIconFont-map, "file-check-03");
  }
  &-file-download-01:before {
    content: map-get($GrowmodoIconFont-map, "file-download-01");
  }
  &-filter-funnel-01:before {
    content: map-get($GrowmodoIconFont-map, "filter-funnel-01");
  }
  &-filter-funnel-02:before {
    content: map-get($GrowmodoIconFont-map, "filter-funnel-02");
  }
  &-filter-lines:before {
    content: map-get($GrowmodoIconFont-map, "filter-lines");
  }
  &-flag-01:before {
    content: map-get($GrowmodoIconFont-map, "flag-01");
  }
  &-flyer-brochure:before {
    content: map-get($GrowmodoIconFont-map, "flyer-brochure");
  }
  &-folder-black:before {
    content: map-get($GrowmodoIconFont-map, "folder-black");
  }
  &-folder-plus:before {
    content: map-get($GrowmodoIconFont-map, "folder-plus");
  }
  &-folder:before {
    content: map-get($GrowmodoIconFont-map, "folder");
  }
  &-format-clear:before {
    content: map-get($GrowmodoIconFont-map, "format-clear");
  }
  &-fullscreen:before {
    content: map-get($GrowmodoIconFont-map, "fullscreen");
  }
  &-gaming-pad-01:before {
    content: map-get($GrowmodoIconFont-map, "gaming-pad-01");
  }
  &-gift-01:before {
    content: map-get($GrowmodoIconFont-map, "gift-01");
  }
  &-gradient:before {
    content: map-get($GrowmodoIconFont-map, "gradient");
  }
  &-grid-01:before {
    content: map-get($GrowmodoIconFont-map, "grid-01");
  }
  &-growmodo-colored:before {
    content: map-get($GrowmodoIconFont-map, "growmodo-colored");
  }
  &-growmodo-d:before {
    content: map-get($GrowmodoIconFont-map, "growmodo-d");
  }
  &-growmodo-g:before {
    content: map-get($GrowmodoIconFont-map, "growmodo-g");
  }
  &-growmodo-m:before {
    content: map-get($GrowmodoIconFont-map, "growmodo-m");
  }
  &-growmodo-o:before {
    content: map-get($GrowmodoIconFont-map, "growmodo-o");
  }
  &-growmodo-r:before {
    content: map-get($GrowmodoIconFont-map, "growmodo-r");
  }
  &-growmodo-w:before {
    content: map-get($GrowmodoIconFont-map, "growmodo-w");
  }
  &-growmodo:before {
    content: map-get($GrowmodoIconFont-map, "growmodo");
  }
  &-heading-01:before {
    content: map-get($GrowmodoIconFont-map, "heading-01");
  }
  &-home-05:before {
    content: map-get($GrowmodoIconFont-map, "home-05");
  }
  &-home-line:before {
    content: map-get($GrowmodoIconFont-map, "home-line");
  }
  &-home-smile:before {
    content: map-get($GrowmodoIconFont-map, "home-smile");
  }
  &-image-03:before {
    content: map-get($GrowmodoIconFont-map, "image-03");
  }
  &-image-04:before {
    content: map-get($GrowmodoIconFont-map, "image-04");
  }
  &-image-indent-left:before {
    content: map-get($GrowmodoIconFont-map, "image-indent-left");
  }
  &-image-plus:before {
    content: map-get($GrowmodoIconFont-map, "image-plus");
  }
  &-infinity:before {
    content: map-get($GrowmodoIconFont-map, "infinity");
  }
  &-info-circle:before {
    content: map-get($GrowmodoIconFont-map, "info-circle");
  }
  &-italic-01:before {
    content: map-get($GrowmodoIconFont-map, "italic-01");
  }
  &-italic-square:before {
    content: map-get($GrowmodoIconFont-map, "italic-square");
  }
  &-layers-three-01:before {
    content: map-get($GrowmodoIconFont-map, "layers-three-01");
  }
  &-layout-alt-02:before {
    content: map-get($GrowmodoIconFont-map, "layout-alt-02");
  }
  &-layout-alt-04:before {
    content: map-get($GrowmodoIconFont-map, "layout-alt-04");
  }
  &-left-indent-01:before {
    content: map-get($GrowmodoIconFont-map, "left-indent-01");
  }
  &-lightning-quick:before {
    content: map-get($GrowmodoIconFont-map, "lightning-quick");
  }
  &-line-chart-up-03:before {
    content: map-get($GrowmodoIconFont-map, "line-chart-up-03");
  }
  &-link-01:before {
    content: map-get($GrowmodoIconFont-map, "link-01");
  }
  &-link-broken-01:before {
    content: map-get($GrowmodoIconFont-map, "link-broken-01");
  }
  &-link-external-01:before {
    content: map-get($GrowmodoIconFont-map, "link-external-01");
  }
  &-list-numbered:before {
    content: map-get($GrowmodoIconFont-map, "list-numbered");
  }
  &-list:before {
    content: map-get($GrowmodoIconFont-map, "list");
  }
  &-loading-01:before {
    content: map-get($GrowmodoIconFont-map, "loading-01");
  }
  &-lock-01:before {
    content: map-get($GrowmodoIconFont-map, "lock-01");
  }
  &-lock-04:before {
    content: map-get($GrowmodoIconFont-map, "lock-04");
  }
  &-log-out-01:before {
    content: map-get($GrowmodoIconFont-map, "log-out-01");
  }
  &-magic-wand-02:before {
    content: map-get($GrowmodoIconFont-map, "magic-wand-02");
  }
  &-mail-02:before {
    content: map-get($GrowmodoIconFont-map, "mail-02");
  }
  &-maximize-02:before {
    content: map-get($GrowmodoIconFont-map, "maximize-02");
  }
  &-message-chat-square:before {
    content: map-get($GrowmodoIconFont-map, "message-chat-square");
  }
  &-message-check-square:before {
    content: map-get($GrowmodoIconFont-map, "message-check-square");
  }
  &-message-dots-square:before {
    content: map-get($GrowmodoIconFont-map, "message-dots-square");
  }
  &-message-heart-square:before {
    content: map-get($GrowmodoIconFont-map, "message-heart-square");
  }
  &-message-question-square:before {
    content: map-get($GrowmodoIconFont-map, "message-question-square");
  }
  &-message-smile-square:before {
    content: map-get($GrowmodoIconFont-map, "message-smile-square");
  }
  &-message-square-02:before {
    content: map-get($GrowmodoIconFont-map, "message-square-02");
  }
  &-minus:before {
    content: map-get($GrowmodoIconFont-map, "minus");
  }
  &-monitor-03:before {
    content: map-get($GrowmodoIconFont-map, "monitor-03");
  }
  &-motion-graphic:before {
    content: map-get($GrowmodoIconFont-map, "motion-graphic");
  }
  &-online-course:before {
    content: map-get($GrowmodoIconFont-map, "online-course");
  }
  &-packaging-design:before {
    content: map-get($GrowmodoIconFont-map, "packaging-design");
  }
  &-palette:before {
    content: map-get($GrowmodoIconFont-map, "palette");
  }
  &-paper-plane-2:before {
    content: map-get($GrowmodoIconFont-map, "paper-plane-2");
  }
  &-passcode-lock:before {
    content: map-get($GrowmodoIconFont-map, "passcode-lock");
  }
  &-pause-circle:before {
    content: map-get($GrowmodoIconFont-map, "pause-circle");
  }
  &-pen-tool-02:before {
    content: map-get($GrowmodoIconFont-map, "pen-tool-02");
  }
  &-pencil-02:before {
    content: map-get($GrowmodoIconFont-map, "pencil-02");
  }
  &-pending:before {
    content: map-get($GrowmodoIconFont-map, "pending");
  }
  &-percent-03:before {
    content: map-get($GrowmodoIconFont-map, "percent-03");
  }
  &-phone-02:before {
    content: map-get($GrowmodoIconFont-map, "phone-02");
  }
  &-phone-call-01:before {
    content: map-get($GrowmodoIconFont-map, "phone-call-01");
  }
  &-play-solid:before {
    content: map-get($GrowmodoIconFont-map, "play-solid");
  }
  &-play:before {
    content: map-get($GrowmodoIconFont-map, "play");
  }
  &-plus-square:before {
    content: map-get($GrowmodoIconFont-map, "plus-square");
  }
  &-plus:before {
    content: map-get($GrowmodoIconFont-map, "plus");
  }
  &-presentation-chart-01:before {
    content: map-get($GrowmodoIconFont-map, "presentation-chart-01");
  }
  &-printer:before {
    content: map-get($GrowmodoIconFont-map, "printer");
  }
  &-puzzle-piece-02:before {
    content: map-get($GrowmodoIconFont-map, "puzzle-piece-02");
  }
  &-puzzle-piece:before {
    content: map-get($GrowmodoIconFont-map, "puzzle-piece");
  }
  &-quote:before {
    content: map-get($GrowmodoIconFont-map, "quote");
  }
  &-refresh-ccw-01:before {
    content: map-get($GrowmodoIconFont-map, "refresh-ccw-01");
  }
  &-refresh-ccw-03:before {
    content: map-get($GrowmodoIconFont-map, "refresh-ccw-03");
  }
  &-refresh-cw-01:before {
    content: map-get($GrowmodoIconFont-map, "refresh-cw-01");
  }
  &-reverse-left:before {
    content: map-get($GrowmodoIconFont-map, "reverse-left");
  }
  &-reverse-right:before {
    content: map-get($GrowmodoIconFont-map, "reverse-right");
  }
  &-right-indent-01:before {
    content: map-get($GrowmodoIconFont-map, "right-indent-01");
  }
  &-rows-03:before {
    content: map-get($GrowmodoIconFont-map, "rows-03");
  }
  &-sale-02:before {
    content: map-get($GrowmodoIconFont-map, "sale-02");
  }
  &-save-01:before {
    content: map-get($GrowmodoIconFont-map, "save-01");
  }
  &-scale-01:before {
    content: map-get($GrowmodoIconFont-map, "scale-01");
  }
  &-search-lg:before {
    content: map-get($GrowmodoIconFont-map, "search-lg");
  }
  &-search-sm:before {
    content: map-get($GrowmodoIconFont-map, "search-sm");
  }
  &-send-03:before {
    content: map-get($GrowmodoIconFont-map, "send-03");
  }
  &-server-01:before {
    content: map-get($GrowmodoIconFont-map, "server-01");
  }
  &-server-05:before {
    content: map-get($GrowmodoIconFont-map, "server-05");
  }
  &-settings-01:before {
    content: map-get($GrowmodoIconFont-map, "settings-01");
  }
  &-settings-02:before {
    content: map-get($GrowmodoIconFont-map, "settings-02");
  }
  &-shield-tick:before {
    content: map-get($GrowmodoIconFont-map, "shield-tick");
  }
  &-shopping-cart-03:before {
    content: map-get($GrowmodoIconFont-map, "shopping-cart-03");
  }
  &-social-angellist:before {
    content: map-get($GrowmodoIconFont-map, "social-angellist");
  }
  &-social-apple:before {
    content: map-get($GrowmodoIconFont-map, "social-apple");
  }
  &-social-clubhouse:before {
    content: map-get($GrowmodoIconFont-map, "social-clubhouse");
  }
  &-social-discord:before {
    content: map-get($GrowmodoIconFont-map, "social-discord");
  }
  &-social-dribbble:before {
    content: map-get($GrowmodoIconFont-map, "social-dribbble");
  }
  &-social-facebook:before {
    content: map-get($GrowmodoIconFont-map, "social-facebook");
  }
  &-social-figma:before {
    content: map-get($GrowmodoIconFont-map, "social-figma");
  }
  &-social-github:before {
    content: map-get($GrowmodoIconFont-map, "social-github");
  }
  &-social-google:before {
    content: map-get($GrowmodoIconFont-map, "social-google");
  }
  &-social-instagram:before {
    content: map-get($GrowmodoIconFont-map, "social-instagram");
  }
  &-social-linkedin:before {
    content: map-get($GrowmodoIconFont-map, "social-linkedin");
  }
  &-social-pinterest:before {
    content: map-get($GrowmodoIconFont-map, "social-pinterest");
  }
  &-social-reddit:before {
    content: map-get($GrowmodoIconFont-map, "social-reddit");
  }
  &-social-signal:before {
    content: map-get($GrowmodoIconFont-map, "social-signal");
  }
  &-social-snapchat:before {
    content: map-get($GrowmodoIconFont-map, "social-snapchat");
  }
  &-social-telegram:before {
    content: map-get($GrowmodoIconFont-map, "social-telegram");
  }
  &-social-tiktok:before {
    content: map-get($GrowmodoIconFont-map, "social-tiktok");
  }
  &-social-tumblr:before {
    content: map-get($GrowmodoIconFont-map, "social-tumblr");
  }
  &-social-twitter:before {
    content: map-get($GrowmodoIconFont-map, "social-twitter");
  }
  &-social-youtube:before {
    content: map-get($GrowmodoIconFont-map, "social-youtube");
  }
  &-speedometer-02:before {
    content: map-get($GrowmodoIconFont-map, "speedometer-02");
  }
  &-square-active:before {
    content: map-get($GrowmodoIconFont-map, "square-active");
  }
  &-square-inactive:before {
    content: map-get($GrowmodoIconFont-map, "square-inactive");
  }
  &-square:before {
    content: map-get($GrowmodoIconFont-map, "square");
  }
  &-star-01:before {
    content: map-get($GrowmodoIconFont-map, "star-01");
  }
  &-star-asterisk:before {
    content: map-get($GrowmodoIconFont-map, "star-asterisk");
  }
  &-star-fill:before {
    content: map-get($GrowmodoIconFont-map, "star-fill");
  }
  &-strikethrough-01:before {
    content: map-get($GrowmodoIconFont-map, "strikethrough-01");
  }
  &-style:before {
    content: map-get($GrowmodoIconFont-map, "style");
  }
  &-tag:before {
    content: map-get($GrowmodoIconFont-map, "tag");
  }
  &-terminal-browser:before {
    content: map-get($GrowmodoIconFont-map, "terminal-browser");
  }
  &-tick:before {
    content: map-get($GrowmodoIconFont-map, "tick");
  }
  &-tool-02:before {
    content: map-get($GrowmodoIconFont-map, "tool-02");
  }
  &-trash-01:before {
    content: map-get($GrowmodoIconFont-map, "trash-01");
  }
  &-triangle-down:before {
    content: map-get($GrowmodoIconFont-map, "triangle-down");
  }
  &-tune:before {
    content: map-get($GrowmodoIconFont-map, "tune");
  }
  &-type-01:before {
    content: map-get($GrowmodoIconFont-map, "type-01");
  }
  &-type-02:before {
    content: map-get($GrowmodoIconFont-map, "type-02");
  }
  &-type-square:before {
    content: map-get($GrowmodoIconFont-map, "type-square");
  }
  &-underline-01:before {
    content: map-get($GrowmodoIconFont-map, "underline-01");
  }
  &-upload-01:before {
    content: map-get($GrowmodoIconFont-map, "upload-01");
  }
  &-upload-04:before {
    content: map-get($GrowmodoIconFont-map, "upload-04");
  }
  &-upload-cloud-01:before {
    content: map-get($GrowmodoIconFont-map, "upload-cloud-01");
  }
  &-user-03:before {
    content: map-get($GrowmodoIconFont-map, "user-03");
  }
  &-user-plus-01:before {
    content: map-get($GrowmodoIconFont-map, "user-plus-01");
  }
  &-users-01:before {
    content: map-get($GrowmodoIconFont-map, "users-01");
  }
  &-users-02:before {
    content: map-get($GrowmodoIconFont-map, "users-02");
  }
  &-users-plus:before {
    content: map-get($GrowmodoIconFont-map, "users-plus");
  }
  &-video-recorder:before {
    content: map-get($GrowmodoIconFont-map, "video-recorder");
  }
  &-wallet-02:before {
    content: map-get($GrowmodoIconFont-map, "wallet-02");
  }
  &-wifi-off:before {
    content: map-get($GrowmodoIconFont-map, "wifi-off");
  }
  &-workflow-setup:before {
    content: map-get($GrowmodoIconFont-map, "workflow-setup");
  }
  &-x-circle:before {
    content: map-get($GrowmodoIconFont-map, "x-circle");
  }
  &-x-close:before {
    content: map-get($GrowmodoIconFont-map, "x-close");
  }
  &-zoom-in:before {
    content: map-get($GrowmodoIconFont-map, "zoom-in");
  }
  &-zoom-out:before {
    content: map-get($GrowmodoIconFont-map, "zoom-out");
  }
}
