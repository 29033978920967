@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.margin {
  &-1 {
    &a {
      margin: map-get($gm-size, 1);
    }
    &t {
      margin-top: map-get($gm-size, 1);
    }
    &b {
      margin-bottom: map-get($gm-size, 1);
    }
    &l {
      margin-left: map-get($gm-size, 1);
    }
    &r {
      margin-right: map-get($gm-size, 1);
    }
    &x {
      margin-left: map-get($gm-size, 1);
      margin-right: map-get($gm-size, 1);
    }
    &y {
      margin-top: map-get($gm-size, 1);
      margin-bottom: map-get($gm-size, 1);
    }
  }

  &-2 {
    &a {
      margin: map-get($gm-size, 2);
    }
    &t {
      margin-top: map-get($gm-size, 2);
    }
    &b {
      margin-bottom: map-get($gm-size, 2);
    }
    &l {
      margin-left: map-get($gm-size, 2);
    }
    &r {
      margin-right: map-get($gm-size, 2);
    }
    &x {
      margin-left: map-get($gm-size, 2);
      margin-right: map-get($gm-size, 2);
    }
    &y {
      margin-top: map-get($gm-size, 2);
      margin-bottom: map-get($gm-size, 2);
    }
  }

  &-3 {
    &a {
      margin: map-get($gm-size, 3);
    }
    &t {
      margin-top: map-get($gm-size, 3);
    }
    &b {
      margin-bottom: map-get($gm-size, 3);
    }
    &l {
      margin-left: map-get($gm-size, 3);
    }
    &r {
      margin-right: map-get($gm-size, 3);
    }
    &x {
      margin-left: map-get($gm-size, 3);
      margin-right: map-get($gm-size, 3);
    }
    &y {
      margin-top: map-get($gm-size, 3);
      margin-bottom: map-get($gm-size, 3);
    }
  }

  &-4 {
    &a {
      margin: map-get($gm-size, 4);
    }
    &t {
      margin-top: map-get($gm-size, 4);
    }
    &b {
      margin-bottom: map-get($gm-size, 4);
    }
    &l {
      margin-left: map-get($gm-size, 4);
    }
    &r {
      margin-right: map-get($gm-size, 4);
    }
    &x {
      margin-left: map-get($gm-size, 4);
      margin-right: map-get($gm-size, 4);
    }
    &y {
      margin-top: map-get($gm-size, 4);
      margin-bottom: map-get($gm-size, 4);
    }
  }

  &-5 {
    &a {
      margin: map-get($gm-size, 5);
    }
    &t {
      margin-top: map-get($gm-size, 5);
    }
    &b {
      margin-bottom: map-get($gm-size, 5);
    }
    &l {
      margin-left: map-get($gm-size, 5);
    }
    &r {
      margin-right: map-get($gm-size, 5);
    }
    &x {
      margin-left: map-get($gm-size, 5);
      margin-right: map-get($gm-size, 5);
    }
    &y {
      margin-top: map-get($gm-size, 5);
      margin-bottom: map-get($gm-size, 5);
    }
  }

  &-6 {
    &a {
      margin: map-get($gm-size, 6);
    }
    &t {
      margin-top: map-get($gm-size, 6);
    }
    &b {
      margin-bottom: map-get($gm-size, 6);
    }
    &l {
      margin-left: map-get($gm-size, 6);
    }
    &r {
      margin-right: map-get($gm-size, 6);
    }
    &x {
      margin-left: map-get($gm-size, 6);
      margin-right: map-get($gm-size, 6);
    }
    &y {
      margin-top: map-get($gm-size, 6);
      margin-bottom: map-get($gm-size, 6);
    }
  }

  &-7 {
    &a {
      margin: map-get($gm-size, 7);
    }
    &t {
      margin-top: map-get($gm-size, 7);
    }
    &b {
      margin-bottom: map-get($gm-size, 7);
    }
    &l {
      margin-left: map-get($gm-size, 7);
    }
    &r {
      margin-right: map-get($gm-size, 7);
    }
    &x {
      margin-left: map-get($gm-size, 7);
      margin-right: map-get($gm-size, 7);
    }
    &y {
      margin-top: map-get($gm-size, 7);
      margin-bottom: map-get($gm-size, 7);
    }
  }

  &-8 {
    &a {
      margin: map-get($gm-size, 8);
    }
    &t {
      margin-top: map-get($gm-size, 8);
    }
    &b {
      margin-bottom: map-get($gm-size, 8);
    }
    &l {
      margin-left: map-get($gm-size, 8);
    }
    &r {
      margin-right: map-get($gm-size, 8);
    }
    &x {
      margin-left: map-get($gm-size, 8);
      margin-right: map-get($gm-size, 8);
    }
    &y {
      margin-top: map-get($gm-size, 8);
      margin-bottom: map-get($gm-size, 8);
    }
  }

  &-9 {
    &a {
      margin: map-get($gm-size, 9);
    }
    &t {
      margin-top: map-get($gm-size, 9);
    }
    &b {
      margin-bottom: map-get($gm-size, 9);
    }
    &l {
      margin-left: map-get($gm-size, 9);
    }
    &r {
      margin-right: map-get($gm-size, 9);
    }
    &x {
      margin-left: map-get($gm-size, 9);
      margin-right: map-get($gm-size, 9);
    }
    &y {
      margin-top: map-get($gm-size, 9);
      margin-bottom: map-get($gm-size, 9);
    }
  }

  &-10 {
    &a {
      margin: map-get($gm-size, 10);
    }
    &t {
      margin-top: map-get($gm-size, 10);
    }
    &b {
      margin-bottom: map-get($gm-size, 10);
    }
    &l {
      margin-left: map-get($gm-size, 10);
    }
    &r {
      margin-right: map-get($gm-size, 10);
    }
    &x {
      margin-left: map-get($gm-size, 10);
      margin-right: map-get($gm-size, 10);
    }
    &y {
      margin-top: map-get($gm-size, 10);
      margin-bottom: map-get($gm-size, 10);
    }
  }

  &-11 {
    &a {
      margin: map-get($gm-size, 11);
    }
    &t {
      margin-top: map-get($gm-size, 11);
    }
    &b {
      margin-bottom: map-get($gm-size, 11);
    }
    &l {
      margin-left: map-get($gm-size, 11);
    }
    &r {
      margin-right: map-get($gm-size, 11);
    }
    &x {
      margin-left: map-get($gm-size, 11);
      margin-right: map-get($gm-size, 11);
    }
    &y {
      margin-top: map-get($gm-size, 11);
      margin-bottom: map-get($gm-size, 11);
    }
  }

  &-12 {
    &a {
      margin: map-get($gm-size, 12);
    }
    &t {
      margin-top: map-get($gm-size, 12);
    }
    &b {
      margin-bottom: map-get($gm-size, 12);
    }
    &l {
      margin-left: map-get($gm-size, 12);
    }
    &r {
      margin-right: map-get($gm-size, 12);
    }
    &x {
      margin-left: map-get($gm-size, 12);
      margin-right: map-get($gm-size, 12);
    }
    &y {
      margin-top: map-get($gm-size, 12);
      margin-bottom: map-get($gm-size, 12);
    }
  }

  &-13 {
    &a {
      margin: map-get($gm-size, 13);
    }
    &t {
      margin-top: map-get($gm-size, 13);
    }
    &b {
      margin-bottom: map-get($gm-size, 13);
    }
    &l {
      margin-left: map-get($gm-size, 13);
    }
    &r {
      margin-right: map-get($gm-size, 13);
    }
    &x {
      margin-left: map-get($gm-size, 13);
      margin-right: map-get($gm-size, 13);
    }
    &y {
      margin-top: map-get($gm-size, 13);
      margin-bottom: map-get($gm-size, 13);
    }
  }

  &-14 {
    &a {
      margin: map-get($gm-size, 14);
    }
    &t {
      margin-top: map-get($gm-size, 14);
    }
    &b {
      margin-bottom: map-get($gm-size, 14);
    }
    &l {
      margin-left: map-get($gm-size, 14);
    }
    &r {
      margin-right: map-get($gm-size, 14);
    }
    &x {
      margin-left: map-get($gm-size, 14);
      margin-right: map-get($gm-size, 14);
    }
    &y {
      margin-top: map-get($gm-size, 14);
      margin-bottom: map-get($gm-size, 14);
    }
  }

  &-15 {
    &a {
      margin: map-get($gm-size, 15);
    }
    &t {
      margin-top: map-get($gm-size, 15);
    }
    &b {
      margin-bottom: map-get($gm-size, 15);
    }
    &l {
      margin-left: map-get($gm-size, 15);
    }
    &r {
      margin-right: map-get($gm-size, 15);
    }
    &x {
      margin-left: map-get($gm-size, 15);
      margin-right: map-get($gm-size, 15);
    }
    &y {
      margin-top: map-get($gm-size, 15);
      margin-bottom: map-get($gm-size, 15);
    }
  }

  &-16 {
    &a {
      margin: map-get($gm-size, 16);
    }
    &t {
      margin-top: map-get($gm-size, 16);
    }
    &b {
      margin-bottom: map-get($gm-size, 16);
    }
    &l {
      margin-left: map-get($gm-size, 16);
    }
    &r {
      margin-right: map-get($gm-size, 16);
    }
    &x {
      margin-left: map-get($gm-size, 16);
      margin-right: map-get($gm-size, 16);
    }
    &y {
      margin-top: map-get($gm-size, 16);
      margin-bottom: map-get($gm-size, 16);
    }
  }

  &-17 {
    &a {
      margin: map-get($gm-size, 17);
    }
    &t {
      margin-top: map-get($gm-size, 17);
    }
    &b {
      margin-bottom: map-get($gm-size, 17);
    }
    &l {
      margin-left: map-get($gm-size, 17);
    }
    &r {
      margin-right: map-get($gm-size, 17);
    }
    &x {
      margin-left: map-get($gm-size, 17);
      margin-right: map-get($gm-size, 17);
    }
    &y {
      margin-top: map-get($gm-size, 17);
      margin-bottom: map-get($gm-size, 17);
    }
  }
}
