@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.height {
  &-1 {
    height: map-get($gm-size, 1);
  }
  &-2 {
    height: map-get($gm-size, 2);
  }
  &-3 {
    height: map-get($gm-size, 3);
  }
  &-4 {
    height: map-get($gm-size, 4);
  }
  &-5 {
    height: map-get($gm-size, 5);
  }
  &-6 {
    height: map-get($gm-size, 6);
  }
  &-7 {
    height: map-get($gm-size, 7);
  }
  &-8 {
    height: map-get($gm-size, 8);
  }
  &-9 {
    height: map-get($gm-size, 9);
  }
  &-10 {
    height: map-get($gm-size, 10);
  }
  &-11 {
    height: map-get($gm-size, 11);
  }
  &-12 {
    height: map-get($gm-size, 12);
  }
  &-13 {
    height: map-get($gm-size, 13);
  }
  &-14 {
    height: map-get($gm-size, 14);
  }
  &-15 {
    height: map-get($gm-size, 15);
  }
  &-16 {
    height: map-get($gm-size, 16);
  }
  &-17 {
    height: map-get($gm-size, 17);
  }

  // set minimum

  &-min {
    &-1 {
      min-height: map-get($gm-size, 1);
    }
    &-2 {
      min-height: map-get($gm-size, 2);
    }
    &-3 {
      min-height: map-get($gm-size, 3);
    }
    &-4 {
      min-height: map-get($gm-size, 4);
    }
    &-5 {
      min-height: map-get($gm-size, 5);
    }
    &-6 {
      min-height: map-get($gm-size, 6);
    }
    &-7 {
      min-height: map-get($gm-size, 7);
    }
    &-8 {
      min-height: map-get($gm-size, 8);
    }
    &-9 {
      min-height: map-get($gm-size, 9);
    }
    &-10 {
      min-height: map-get($gm-size, 10);
    }
    &-11 {
      min-height: map-get($gm-size, 11);
    }
    &-12 {
      min-height: map-get($gm-size, 12);
    }
    &-13 {
      min-height: map-get($gm-size, 13);
    }
    &-14 {
      min-height: map-get($gm-size, 14);
    }
    &-15 {
      min-height: map-get($gm-size, 15);
    }
    &-16 {
      min-height: map-get($gm-size, 16);
    }
    &-17 {
      min-height: map-get($gm-size, 17);
    }
  }
}
