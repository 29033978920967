@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// Lighbox
.vel {
  &-modal {
    background-color: $dimmed-background !important;
    &.dark {
      background-color: $dimmed-background-dark !important;
    }
    & .vel-img {
      background-color: $dimmed-background;
      border-radius: $border;
      padding: $border;
    }
  }
}
