@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@font-face {
  font-family: 'TTNormsPro-BlkIt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-BlkIt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-BlkIt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-BlkIt.ttf') format('truetype');
  font-weight: $font-black;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-Blk';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-Blk.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Blk.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Blk.ttf') format('truetype');
  font-weight: $font-black;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-BdIt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-BdIt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-BdIt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-BdIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-DmBd';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-DmBd.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-DmBd.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-DmBd.ttf') format('truetype');
  font-weight: $font-demi-bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-Bd';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-Bd.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Bd.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-XBdIt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-XBdIt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XBdIt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XBdIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-DmBdIt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-DmBdIt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-DmBdIt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-DmBdIt.ttf') format('truetype');
  font-weight: $font-demi-bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-XBlkIt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-XBlkIt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XBlkIt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XBlkIt.ttf') format('truetype');
  font-weight: $font-extra-black;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-XBlk';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-XBlk.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XBlk.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XBlk.ttf') format('truetype');
  font-weight: $font-extra-black;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-XBd';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-XBd.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XBd.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XBd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-XLt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-XLt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XLt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XLt.ttf') format('truetype');
  font-weight: $font-extra-light;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-XLtIt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-XLtIt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XLtIt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-XLtIt.ttf') format('truetype');
  font-weight: $font-extra-light;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-Lt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-Lt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Lt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Lt.ttf') format('truetype');
  font-weight: $font-light;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-LtIt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-LtIt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-LtIt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-LtIt.ttf') format('truetype');
  font-weight: $font-light;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-It';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-It.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-It.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-It.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-MdIt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-MdIt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-MdIt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-MdIt.ttf') format('truetype');
  font-weight: $font-medium;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-Md';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-Md.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Md.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Md.ttf') format('truetype');
  font-weight: $font-medium;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-Normal';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-Normal.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Normal.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Normal.ttf') format('truetype');
  font-weight: $font-normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-NormalIt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-NormalIt.woff2')
      format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-NormalIt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-NormalIt.ttf') format('truetype');
  font-weight: $font-normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-Rg';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-Rg.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Rg.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-ThIt';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-ThIt.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-ThIt.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-ThIt.ttf') format('truetype');
  font-weight: $font-thin;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TTNormsPro-Th';
  src: url('~assets/fonts/tt-norms-pro/TTNormsPro-Th.woff2') format('woff2'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Th.woff') format('woff'),
    url('~assets/fonts/tt-norms-pro/TTNormsPro-Th.ttf') format('truetype');
  font-weight: $font-thin;
  font-style: normal;
  font-display: swap;
}
