
.gm-toggle {
  & .gm {
    &-switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 25px;
      min-width: 50px;

      & .gm-switch {
        &-input {
          opacity: 0;
          width: 0;
          height: 0;

          &:checked + .gm-slider {
            background-color: currentColor;
          }
          &:focus + .gm-slider {
            box-shadow: 0 0 1px currentColor;
          }
          &:checked + .gm-slider:before {
            -webkit-transform: translateX(24px);
            -ms-transform: translateX(24px);
            transform: translateX(24px);
          }
        }
      }
    }
    &-slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: currentColor;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      &.gm-toggle-rounded {
        border-radius: 34px;

        &:before {
          border-radius: 50%;
        }
      }
    }
  }
}
