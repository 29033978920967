@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
/*
 * Breakpoint variable, from https://quasar.dev/style/breakpoints
 */
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: $breakpoint-xs-max) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: $breakpoint-xs-max) {
  .container {
    max-width: 920px;
  }
}

@media (min-width: $breakpoint-sm-max) {
  .container {
    max-width: 1080px;
  }
}

@media (min-width: $breakpoint-md-max) {
  .container {
    max-width: 1200px;
  }
}
