@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.hover {
  &-up {
    &-1 {
      &:hover {
        z-index: 1;
      }
    }
    &-2 {
      &:hover {
        z-index: 2;
      }
    }
    &-3 {
      &:hover {
        z-index: 3;
      }
    }
  }
  &-shadows {
    &-gm {
      &:before {
        transition: $shadow-transition;
      }
      &:hover {
        &:before {
          box-shadow: $shadow-gm !important;
        }
        & > .q-focus-helper {
          opacity: 0 !important;
        }
      }
    }
  }
  &-img {
    &-nograyscale {
      &:not(:hover) {
        & img {
          filter: grayscale(100%);
          /* filter: sepia(100%) hue-rotate(190deg) saturate(500%); // Blue */
        }
      }
    }
  }
  &-dark {
    transition: all 0.3s;
    &.q-btn {
      &:hover {
        & > .q-focus-helper {
          background: $primary !important;
          opacity: 1 !important;
          &:before,
          &::after {
            background: $primary !important;
            opacity: 1 !important;
          }
        }
        & .q-btn__content {
          color: white;
        }
      }
    }
    &:hover {
      background: $primary !important;
      color: white;
    }
  }
  &-negative {
    transition: all 0.3s;
    &.q-btn {
      &:hover {
        & > .q-focus-helper {
          background: $negative !important;
          opacity: 1 !important;
          &:before,
          &::after {
            background: $negative !important;
            opacity: 1 !important;
          }
        }
        & .q-btn__content {
          color: white;
        }
      }
    }
    &:hover {
      background: $negative;
      color: white;
    }
  }
  &-bg {
    &-secondary2 {
      transition: all $animate-duration;
      &:hover {
        background: $secondary-600 !important;
        & > .q-focus-helper {
          background: $secondary-600 !important;
          &:before,
          &:after {
            background: $secondary-600 !important;
          }
        }
      }
      & > .q-focus-helper {
        background: $secondary-600 !important;
        &:before,
        &:after {
          background: $secondary-600 !important;
        }
      }
      & .hover-bg-secondary2 {
        &:hover {
          background: $secondary-600 !important;
        }
      }
      & .td {
        &:after,
        &:before {
          background: $secondary-600 !important;
        }
      }
    }
    &-accent {
      transition: all $animate-duration;
      &:hover {
        background: $accent !important;
        & > .q-focus-helper {
          background: $accent !important;
          &:before,
          &:after {
            background: $accent !important;
          }
        }
      }
      & > .q-focus-helper {
        background: $accent !important;
        &:before,
        &:after {
          background: $accent !important;
        }
      }
      & .hover-bg-accent {
        &:hover {
          background: $accent !important;
        }
      }
      & .td {
        &:after,
        &:before {
          background: $accent !important;
        }
      }
    }
    &-secondary {
      transition: all $animate-duration;
      &:hover {
        background: $secondary !important;
        & .hover-bg-secondary {
          color: $secondary !important;
        }
      }
      & > .q-focus-helper {
        background: $secondary !important;
        &:before,
        &:after {
          background: $secondary !important;
        }
      }
      & .td {
        &:after,
        &:before {
          background: $secondary !important;
        }
      }
    }
    &-transparent {
      transition: all $animate-duration;
      &:hover {
        background: transparent !important;
        & .hover-bg-transparent {
          color: transparent !important;
        }
      }
      & > .q-focus-helper {
        background: transparent !important;
        &:before,
        &:after {
          background: transparent !important;
        }
      }
      & .td {
        &:after,
        &:before {
          background: transparent !important;
        }
      }
    }
  }
  &-text {
    &-secondary {
      transition: all $animate-duration;
      &:hover {
        color: $secondary !important;
        & .q-icon,
        & .hover-text-secondary {
          color: $secondary !important;
        }
      }
    }
    &-accent {
      transition: all $animate-duration;
      &:hover {
        color: $accent !important;
        & .hover-text-accent {
          color: $accent !important;
        }
      }
    }
    &-negative {
      transition: all $animate-duration;
      &:hover {
        color: $negative !important;
        & .q-icon,
        & .hover-text-negative {
          color: $negative !important;
        }
      }
    }
    &-logo3 {
      transition: all $animate-duration;
      &:hover {
        color: map-get($gm-logo, 'color-3') !important;
        & .q-icon,
        & .hover-text-logo3 {
          color: map-get($gm-logo, 'color-3') !important;
        }
      }
    }
  }
  &-border {
    &-primary {
      transition: all $animate-duration;
      &:hover,
      &:focus,
      &:active {
        border-color: $primary;
        &.border-dashed {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='#{str-replace(#{$primary}, '#', '%23')}' stroke-width='#{map-get($borders, 'lg')}' stroke-dasharray='10%2c 10' stroke-dashoffset='21' stroke-linecap='round'/%3e%3c/svg%3e");
          background-repeat: none;
        }
        & .q-separator.hover-primary {
          background: $primary;
        }
        &.q-btn--outline::before {
          border-color: $primary;
        }
      }
    }
    &-secondary {
      transition: all $animate-duration;
      &:hover,
      &:focus,
      &:active {
        border-color: $secondary;
        &.border-dashed {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='#{str-replace(#{$secondary}, '#', '%23')}' stroke-width='#{map-get($borders, 'lg')}' stroke-dasharray='10%2c 10' stroke-dashoffset='21' stroke-linecap='round'/%3e%3c/svg%3e");
          background-repeat: none;
        }
        & .q-separator.hover-secondary {
          background: $secondary;
        }
        &.q-btn--outline::before {
          border-color: $secondary;
        }
      }
    }
    &-color {
      transition: all $animate-duration;
      &:hover,
      &:focus,
      &:active {
        border-color: $border-color;
        &.border-dashed {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='#{str-replace(#{$border-color}, '#', '%23')}' stroke-width='#{map-get($borders, 'lg')}' stroke-dasharray='10%2c 10' stroke-dashoffset='21' stroke-linecap='round'/%3e%3c/svg%3e");
          background-repeat: none;
        }
        & .q-separator.hover-color {
          background: $border-color;
        }
        &.q-btn--outline::before {
          border-color: $border-color;
        }
      }
    }
    &-negative {
      transition: all $animate-duration;
      &:hover,
      &:focus,
      &:active {
        border-color: $negative;
        &.border-dashed {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='#{str-replace(#{$negative}, '#', '%23')}' stroke-width='#{map-get($borders, 'lg')}' stroke-dasharray='10%2c 10' stroke-dashoffset='21' stroke-linecap='round'/%3e%3c/svg%3e");
          background-repeat: none;
        }
        & .q-separator.hover-negative {
          background: $negative;
        }
        &.q-btn--outline::before {
          border-color: $negative;
        }
      }
    }
  }
  &-shrink {
    &-wrapper {
      &:hover {
        & .hover-shrink {
          transform: scale(0.97);
        }
      }
    }
    transition: transform 0.5s;
    &:hover {
      transform: scale(0.97);
    }
  }
  &-visibility {
    & .hover-visibility {
      &-show {
        visibility: hidden;
      }
    }
    &:hover {
      & .hover-visibility {
        &-show {
          opacity: 1;
          visibility: visible;
        }
        &-hide {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
  &-font {
    &-italic {
      &:hover {
        font-style: italic;
      }
    }
    &-extra {
      &-light {
        &:hover {
          font-weight: $font-extra-light;
        }
      }
      &-bold {
        &:hover {
          font-weight: bold;
        }
      }
      &-black {
        &:hover {
          font-weight: $font-extra-black;
        }
      }
    }
    &-thin {
      &:hover {
        font-weight: $font-thin;
      }
    }
    &-light {
      &:hover {
        font-weight: $font-light;
      }
    }
    &-normal {
      &:hover {
        font-weight: normal;
      }
    }
    &-medium {
      &:hover {
        font-weight: $font-medium;
      }
    }
    &-bold {
      &:hover {
        font-weight: bold;
      }
    }
    &-black {
      &:hover {
        font-weight: $font-black;
      }
    }
  }
}
