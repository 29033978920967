@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.font {
  &-italic {
    font-family: 'TTNormsPro-It';
    font-weight: normal;
    font-style: italic;
  }
  &-extra {
    &-light {
      font-family: 'TTNormsPro-XLt';
      font-weight: $font-extra-light;
      &-italic {
        font-family: 'TTNormsPro-XLtIt';
        font-weight: $font-extra-light;
        font-style: italic;
      }
    }
    &-bold {
      font-family: 'TTNormsPro-XBd';
      font-weight: bold;
      &-italic {
        font-family: 'TTNormsPro-XBdIt';
        font-weight: bold;
        font-style: italic;
      }
    }
    &-black {
      font-family: 'TTNormsPro-XBlk';
      font-weight: $font-extra-black;
      &-italic {
        font-family: 'TTNormsPro-XBlkIt';
        font-weight: $font-extra-black;
        font-style: italic;
      }
    }
  }
  &-thin {
    font-family: 'TTNormsPro-Th';
    font-weight: $font-thin;
    &-thin {
      font-family: 'TTNormsPro-ThIt';
      font-weight: $font-thin;
      font-style: italic;
    }
  }
  &-light {
    font-family: 'TTNormsPro-Lt';
    font-weight: $font-light;
    &-italic {
      font-family: 'TTNormsPro-LtIt';
      font-weight: $font-light;
      font-style: italic;
    }
  }
  &-regular {
    font-family: 'TTNormsPro-Rg';
    font-weight: normal;
  }
  &-normal {
    font-family: 'TTNormsPro-Normal';
    font-weight: $font-normal;
    &-normal {
      font-family: 'TTNormsPro-NormalIt';
      font-weight: $font-normal;
      font-style: italic;
    }
  }
  &-medium {
    font-family: 'TTNormsPro-Md';
    font-weight: $font-medium;
    &-italic {
      font-family: 'TTNormsPro-MdIt';
      font-weight: $font-medium;
      font-style: italic;
    }
  }
  &-bold {
    font-family: 'TTNormsPro-Bd';
    font-weight: bold;
    &-italic {
      font-family: 'TTNormsPro-BdIt';
      font-weight: bold;
      font-style: italic;
    }
  }
  &-demi {
    &-bold {
      font-family: 'TTNormsPro-DmBd';
      font-weight: $font-demi-bold;
      &-italic {
        font-family: 'TTNormsPro-DmBdIt';
        font-weight: $font-demi-bold;
        font-style: italic;
      }
    }
  }
  &-black {
    font-family: 'TTNormsPro-Blk';
    font-weight: $font-black;
    &-italic {
      font-family: 'TTNormsPro-BlkIt';
      font-weight: $font-black;
      font-style: italic;
    }
  }
}
