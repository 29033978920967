@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// $
/* Font Styles */
@import url(styles/_fonts.scss);
/* Placeholder style */
@import url(styles/_placeholder.scss);
/* Custom Icon Font */
@import url(styles/_icons-font.scss);
/* Custom Classes */
@import url(styles/_classes.scss);
/* Main Drawer */
@import url(styles/_main_drawer.scss);

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  shape-rendering: crispEdges;
}

html {
  font-size: $body-font-size;
}

body {
  letter-spacing: map-get($letter-spacing, 'standard');
  color: $primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  color: $secondary;
  &:hover {
    color: $secondary;
  }
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}

pre {
  white-space: pre-wrap;
  background-color: $primary-100;
  border: 1px solid $border-color;
  border-radius: 4px;
  color: $warning-color;
  padding: 5px 10px;
  margin: 10px 0;
}

.q-field {
  &__before,
  &__prepend,
  &__after,
  &__append {
    &:empty {
      display: none;
    }
  }
}

.q {
  &-radio {
    font-family: 'TTNormsPro-Md';
    font-size: map-get($body1, 'size');
    &.q-radio--dense,
    &.input-sm {
      height: map-get($field-small, 'height');
    }
    &:not(.q-radio--dense),
    &.radio-md {
      height: map-get($field-normal, 'height');
    }
    &:not(.q-radio--dense).radio-lg {
      height: map-get($field-large, 'height');
    }
  }
  &-field {
    font-family: 'TTNormsPro-Md';
    font-size: map-get($body1, 'size');
    &:not(.q-textarea, .input-lg) {
      &.q-field--dense,
      &.input-sm {
        height: map-get($field-small, 'height');
        & .q-field__control,
        & .q-field__native,
        & .q-field__marginal {
          height: map-get($field-small, 'height');
          min-height: map-get($field-small, 'height');
        }
      }
      &:not(.q-field--dense),
      &.input-md {
        height: map-get($field-normal, 'height');
        & .q-field__control,
        & .q-field__native,
        & .q-field__marginal {
          height: map-get($field-normal, 'height');
          min-height: map-get($field-normal, 'height');
        }
      }
      &:not(.q-field--dense).input-lg {
        height: map-get($field-large, 'height');
        & .q-field__control,
        & .q-field__native,
        & .q-field__marginal {
          height: map-get($field-large, 'height');
          min-height: map-get($field-large, 'height');
        }
      }
    }
  }
  &-menu {
    border: 1px solid $primary-100;
    box-shadow: map-get($gm-shadows, 'lg');
  }
  &-list {
    &--separator {
      min-width: 194px;
      & > .q-item-type + .q-item-type,
      & > .q-virtual-scroll__content > .q-item-type + .q-item-type {
        border-top: 1px solid $primary-100;
      }
      & .q-item {
        color: $primary-700;
        &__section {
          &--main {
            font-size: map-get($body2, 'size');
          }
          &--side {
            color: $primary-700;
            font-size: map-get($body2, 'size');
          }
        }
      }
    }
  }

  &-editor {
    &:hover {
      border-color: $secondary;
    }
    &:active,
    &:focus-visible,
    &:focus-within,
    &:focus-within,
    &.is-focused {
      border-color: $secondary;
      box-shadow: map-get($gm-shadows, 'active') !important;
    }
    border-color: $border-color;
  }

  &-field {
    &:not(.no-box-shadow, .q-field--standard) {
      & > .q-field__inner > .q-field__control:before {
        border-radius: $generic-border-radius;
        box-shadow: $shadow-field;
      }
    }
    &--focused,
    &--highlighted,
    &:active,
    &:focus-visible,
    &:focus-within,
    &:focus-within,
    &.is-focused {
      &:not(.no-box-shadow, .q-field--standard, .q-field--error, .input-split) {
        & > .q-field__inner > .q-field__control:before {
          box-shadow: map-get($gm-shadows, 'active') !important;
        }
      }
    }
    &--focused,
    &:active,
    &:focus-visible,
    &:focus-within,
    &:focus-within,
    &.is-focused {
      &.q-field--error:not(.no-box-shadow, .q-field--standard, .input-split) {
        & > .q-field__inner > .q-field__control:before {
          box-shadow: map-get($gm-shadows, 'error') !important;
        }
      }
    }
    &--focused,
    &--highlighted,
    &:hover,
    &:active,
    &:focus-visible,
    &:focus-within,
    &:focus-within,
    &.is-focused {
      &:not(.q-field--error) {
        & .hover {
          &-bg {
            &-primary {
              background: $primary !important;
            }
            &-secondary {
              background: $secondary !important;
            }
            &-accent {
              background: $accent !important;
            }
            &-negative {
              background: $negative !important;
            }
          }
          &-text {
            &-primary {
              color: $primary !important;
            }
            &-secondary {
              color: $secondary !important;
            }
            &-accent {
              color: $accent !important;
            }
            &-negative {
              color: $negative !important;
            }
          }
        }
        &:not(.input-split) .q-separator {
          border-color: $secondary !important;
        }
      }
      &.q-field--error {
        border-color: $negative !important;
        & [class*='hover-text'] {
          color: $negative !important;
        }
        & [class*='hover-bg'] {
          background: $negative !important;
        }
      }
    }
    & .q-field {
      &__control {
        &:before,
        &:after {
          border-width: map-get($borders, 'normal');
          border-color: $border-color;
        }
      }
    }
    & .q-separator {
      transition: $generic-hover-transition;
      width: 0;
      border-left: map-get($borders, 'normal') solid $border-color;
    }
    &--outlined {
      & .q-field {
        &__control {
          &:before,
          &:after {
            border-width: map-get($borders, 'normal');
            border-color: $border-color;
          }
        }
      }
    }
    &.format {
      &-bold {
        &.q-field {
          &:not(.input-split) .q-separator {
            transition: $generic-hover-transition;
            border-left: map-get($borders, 'md') solid $primary;
          }
          &--outlined {
            & .q-field {
              &__control {
                &:before,
                &:after {
                  border-width: map-get($borders, 'md');
                  border-color: $primary;
                }
              }
            }
          }
        }
        &-light {
          &.q-field {
            &:not(.input-split) .q-separator {
              transition: $generic-hover-transition;
              border-left: map-get($borders, 'md') solid $border-color;
            }
            &--outlined {
              & .q-field {
                &__control {
                  &:before,
                  &:after {
                    border-width: map-get($borders, 'md');
                    border-color: $border-color;
                  }
                }
              }
            }
          }
        }
        &.format-border-normal {
          &.q-field {
            &:not(.input-split) .q-separator {
              border-width: map-get($borders, 'normal');
            }
            &--outlined {
              & .q-field {
                &__control {
                  &:before,
                  &:after {
                    border-width: map-get($borders, 'normal');
                  }
                }
              }
            }
          }
          &-light {
            &.q-field {
              &:not(.input-split) .q-separator {
                border-width: map-get($borders, 'normal');
              }
              &--outlined {
                & .q-field {
                  &__control {
                    &:before,
                    &:after {
                      border-width: map-get($borders, 'normal');
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.table-select-filter {
      & .q-field {
        &__native {
          width: auto !important;
          font-weight: $font-medium;
          font-size: map-get($body2, 'size');
        }
      }
    }
    &:not(.input-split).q-field {
      &--focused,
      &--highlighted,
      &:hover,
      &:active,
      &:focus-visible,
      &:focus-within,
      &:focus-within,
      &.is-focused {
        & .q-field {
          &__control {
            &:not(.text-negative) {
              &:before,
              &:after {
                border-color: $secondary;
              }
            }
            &.text-negative {
              &:before,
              &:after {
                border-color: $negative;
              }
            }
          }
        }
      }
    }
    &--outlined {
      &:before,
      &:after {
        border-width: map-get($borders, 'normal');
      }
      & .q-field {
        &__control {
          border-radius: $border;
          &:before,
          &:after {
            border-color: $border-color;
          }
        }
      }
    }
    &.q-select {
      & .q-select {
        &__dropdown-icon {
          color: $placeholder-color;
        }
      }
    }
  }

  &-textarea {
    & .q-field__control {
      height: auto;
    }
  }

  &-dialog {
    &__title {
      letter-spacing: map-get($letter-spacing, 'standard');
    }
    &.backdrop {
      &-light {
        & .q-dialog__backdrop {
          background-color: $light-dimmed-background;
        }
      }
      &-dark {
        & .q-dialog__backdrop {
          background-color: $dimmed-background;
        }
      }
      &-blur {
        & .q-dialog__backdrop {
          backdrop-filter: blur(7px);
        }
      }
    }
  }

  &-card {
    &.action {
      &-absolute {
        & .q-card__actions {
          margin: 0;
          padding: 0;
          height: 0;
          min-height: unset;
        }
      }
      &-none {
        & .q-card__actions {
          display: none;
        }
      }
    }
    &--bordered {
      border-color: $border-color;
    }
  }

  &-uploader {
    &.files-uploader {
      & .q-uploader {
        &__header {
          height: inherit;
          background: transparent;
        }
        &__list {
          padding: 0;
          min-height: unset;
        }
      }
      &.with-items {
        max-height: 450px;
      }
      &:not(.with-items) {
        & .q-uploader {
          &__list {
            display: none;
          }
        }
      }
    }
  }

  &-pagination {
    &.rounded {
      & .q-btn-item {
        border-radius: $border;
      }
    }
  }

  &-rating {
    &.no-shadow {
      & .q-rating__icon {
        text-shadow: unset;
      }
    }
  }

  &-radio {
    &.flex-label {
      & .q-radio {
        &__label {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &-table {
    & .q-table {
      &__progress > th {
        position: sticky;
        z-index: 4;
        top: 1px;
      }
    }
    &__container {
      & tbody {
        & tr {
          &.selected {
            & td {
              &:before,
              &:after {
                background: unset !important;
              }
            }
          }
          & td {
            &:before,
            &:after {
              background: unset !important;
            }
          }
        }
      }
    }
  }

  &-date {
    &__calendar {
      &-item {
        & .q-btn {
          &:before {
            box-shadow: unset !important;
          }
        }
      }
    }
  }
}
