@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.letter {
  &-space {
    &-negative {
      letter-spacing: map-get($letter-spacing, 'negative');
    }
    &-standard {
      letter-spacing: map-get($letter-spacing, 'standard');
    }
    &-normal {
      letter-spacing: map-get($letter-spacing, 'normal');
    }
    &-sm {
      letter-spacing: map-get($letter-spacing, 'sm');
    }
    &-md {
      letter-spacing: map-get($letter-spacing, 'md');
    }
    &-lg {
      letter-spacing: map-get($letter-spacing, 'lg');
    }
  }
}

.word {
  &-break {
    word-wrap: break-word;
  }
}

.text {
  &-ellipssis {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
