@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.border {
  &-add {
    border: map-get($borders, 'normal') solid $border-color;
  }
  &-white {
    border-color: white;
    &.q-btn--outline::before {
      border-color: white;
    }
  }
  &-secondary {
    border-color: $secondary;
    &.q-btn--outline::before {
      border-color: $secondary;
    }
  }
  &-primary {
    border-color: $primary;
    &.q-btn--outline::before {
      border-color: $primary;
    }
  }
  &-info {
    border-color: $info;
    &.q-btn--outline::before {
      border-color: $info;
    }
  }
  &-negative {
    border-color: $negative;
    &.q-btn--outline::before {
      border-color: $negative;
    }
  }
  &-color {
    border-color: $border-color;
    &.q-btn--outline::before {
      border-color: $border-color;
    }
  }
  &-md {
    border-width: map-get($borders, 'md');
  }
  &-lg {
    border-width: map-get($borders, 'lg');
  }
  &-xl {
    border-width: map-get($borders, 'xl');
  }
  &-dashed {
    border-style: none;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='#{str-replace(#{$border-color}, '#', '%23')}' stroke-width='#{map-get($borders, 'lg')}' stroke-dasharray='10%2c 10' stroke-dashoffset='21' stroke-linecap='round'/%3e%3c/svg%3e");
  }
  &-solid {
    border-style: solid;
  }
}

.rounded {
  border-radius: $border !important;
  &-top {
    border-top-left-radius: $border;
    border-top-right-radius: $border;
  }
  &-bottom {
    border-bottom-left-radius: $border;
    border-bottom-right-radius: $border;
  }
  &-left {
    border-top-left-radius: $border;
    border-bottom-left-radius: $border;
  }
  &-right {
    border-top-right-radius: $border;
    border-bottom-right-radius: $border;
  }
}

.no-border {
  &-left {
    border-left: 0;
  }
  &-right {
    border-right: 0;
  }
  &-top {
    border-top: 0;
  }
  &-bottom {
    border-bottom: 0;
  }
}
