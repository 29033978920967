@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@import url(_classes/_index.scss);
@import url(_extended_component.scss);

/* Other Custom Classess */
.round {
  border-radius: 50%;
}

.cursor {
  &-wait {
    cursor: wait;
  }
  &-help {
    cursor: help;
  }
  &-grab {
    cursor: grab;
  }
  &-grabbing {
    cursor: grabbing;
  }
}

.negative-margin {
  &-1 {
    margin-top: -8px;
  }
  &-2 {
    margin-top: -14px;
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  &-menu {
    &-top {
      top: 25px;
    }
  }
}

.underline {
  text-decoration: underline;
}

.full {
  &-opacity {
    opacity: 1;
  }
}

.disabled {
  &-state {
    color: $grey-4 !important;
    border-color: $grey-3 !important;
    & * {
      color: $grey-8 !important;
      border-color: $grey-3 !important;
    }
  }
}

.target {
  &-clickable {
    &-id {
      &:hover {
        cursor: pointer;
        &.with {
          &-hash {
            &:after {
              content: ' #';
            }
          }
        }
      }
    }
  }
}

.transition {
  transition: $generic-hover-transition;
  &-md {
    transition: map-get($transition-durations, 'md')
      cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  &-lg {
    transition: map-get($transition-durations, 'lg')
      cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  &-xl {
    transition: map-get($transition-durations, 'xl')
      cubic-bezier(0.25, 0.8, 0.5, 1);
  }
}

.auto {
  &-h {
    height: auto;
    &-strict {
      height: auto !important;
    }
  }
  &-w {
    width: auto;
    &-strict {
      width: auto !important;
    }
  }
  &-hw {
    height: auto;
    width: auto;
    &-strict {
      height: auto !important;
      width: auto !important;
    }
  }
}

.small {
  &-innersearch {
    & input {
      max-width: 5px !important;
    }
  }
  &-title {
    line-height: 0rem;
  }
}

.no {
  &-min {
    &-height {
      min-height: unset;
    }
    &-width {
      min-width: unset;
    }
  }
  &-hover {
    &:hover {
      &::before,
      &::after {
        opacity: 0 !important;
      }
      &-helper {
        & .q-focus-helper {
          opacity: 0 !important;
        }
      }
    }
  }
  &-ellipsis {
    white-space: normal;
    text-overflow: unset;
    overflow: unset;
  }
  &-border {
    border: 0 !important;
    & .q-field__control {
      border: 0 !important;
      &::before,
      &::after {
        border: 0 !important;
      }
    }
  }
  &-box {
    &-shadow {
      box-shadow: unset !important;
      & .q-field__control {
        box-shadow: unset !important;
        &::before,
        &::after {
          box-shadow: unset !important;
        }
      }
    }
  }
}

.dnav {
  .dicon {
    transition-delay: 0.4s;
    color: $grey-4;

    &.active,
    &.past {
      color: $secondary;
    }
  }

  .dline {
    height: 3px;
    width: 100px;
    max-width: 100%;
    background-color: $grey-4;

    background: linear-gradient(to left, $grey-4 50%, $secondary 50%) right;
    background-size: 200%;
    transition: 0.5s ease-out;
    &-mini {
      width: 70px;
      &-xs {
        width: 10px !important;
      }
    }
    &.active,
    &.past {
      background-position: left;
    }
  }
}

.field {
  &-shadow {
    box-shadow: $shadow-field;
  }
  &-select {
    &.format-bold {
      border-width: map-get($borders, 'md');
      border-color: $primary;
      &:hover,
      &:active,
      &:focus,
      &:focus-visible,
      &[aria-checked='true'] {
        border-width: map-get($borders, 'md');
      }
    }
    border: map-get($borders, 'normal') solid $border-color;
    &:not(.disabled),
    &:not([aria-disabled='true']) {
      &:hover,
      &:active,
      &:focus,
      &:focus-visible,
      &[aria-checked='true'] {
        border-width: map-get($borders, 'normal');
        border-color: $secondary;
        color: $secondary;
        background-color: $accent;
      }
    }
    &-custom {
      & .q-radio__inner,
      & .q-checkbox__inner {
        height: auto;
        &:before {
          display: none !important;
        }
      }
    }
    &-none {
      & .q-radio__inner,
      & .q-checkbox__inner {
        display: none;
      }
    }
  }
}
