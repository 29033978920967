@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.card {
  &-brand {
    &-title {
      padding: 40px 41px 0 41px;
    }
  }
  &-padding {
    padding: $card-base-padding;
    &-title {
      padding: $card-base-padding-sm $card-base-padding;
      &-xs {
        display: flex;
        flex-wrap: wrap;
        padding: $card-base-padding-xs $card-base-padding;
        min-height: 72px;
        & .q-toolbar {
          min-height: unset !important;
        }
      }
    }
    &-xs {
      padding: $card-base-padding-xs $card-base-padding;
    }
    &-sm {
      padding: $card-base-padding-sm;
    }
    &-md {
      padding: $card-base-padding-xl $card-base-padding-sm;
    }
    &-lg {
      padding: $card-base-padding-md $card-base-padding-xl;
    }
    &-xl {
      padding: $card-base-padding-xl $card-base-padding-lg;
    }
  }
  &-p {
    &t {
      padding-top: $card-base-padding;
      &-sm {
        padding-top: $card-base-padding-sm;
      }
      &-md {
        padding-top: $card-base-padding-xl;
      }
    }
    &b {
      padding-bottom: $card-base-padding;
      &-sm {
        padding-bottom: $card-base-padding-sm;
      }
      &-md {
        padding-bottom: $card-base-padding-xl;
      }
    }
    &l {
      padding-left: $card-base-padding;
      &-sm {
        padding-left: $card-base-padding-sm;
      }
      &-md {
        padding-left: $card-base-padding-xl;
      }
    }
    &right {
      padding-right: $card-base-padding;
      &-sm {
        padding-right: $card-base-padding-sm;
      }
      &-md {
        padding-right: $card-base-padding-xl;
      }
    }
    &y {
      padding-top: $card-base-padding;
      padding-bottom: $card-base-padding;
      &-sm {
        padding-top: $card-base-padding-sm;
        padding-bottom: $card-base-padding-sm;
      }
      &-md {
        padding-top: $card-base-padding-xl;
        padding-bottom: $card-base-padding-xl;
      }
    }
    &x {
      padding-left: $card-base-padding;
      padding-right: $card-base-padding;
      &-sm {
        padding-left: $card-base-padding-sm;
        padding-right: $card-base-padding-sm;
      }
      &-md {
        padding-left: $card-base-padding-xl;
        padding-right: $card-base-padding-xl;
      }
    }
  }
  &-quicklinks {
    width: 100%;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
    overflow: hidden;
    border-width: map-get($borders, 'normal');
    border-style: solid;
    border-color: $border-color;
    border-radius: $border;
    &:not(.no-min-height) {
      min-height: $card-height;
      &.card-quicklinks-new {
        min-height: $card-height;
      }
      &.card-quicklinks-mini {
        min-height: $card-height-mini;
      }
      &.brand {
        min-height: $card-height;
      }
    }

    &.brand > .q-card {
      &__section {
        min-height: 132px;
      }
    }

    & .q-separator {
      height: 1px;
      line-height: 1rem;
      margin: 0;
      padding: 0;
      border: 0;
    }

    &.brand {
      & .brand-title {
        max-height: 88px;
        overflow-y: hidden;
        text-overflow: ellipsis;
      }
      max-height: 100%;
    }

    &-mini {
      max-height: 100%;
    }
    & .card-bg-circle {
      transition: transform 0.5s;
      color: rgba($accent, 0.25);
      display: inline;
      position: absolute;
      right: -35px;
      top: -35px;
    }
    &:hover {
      z-index: 3;
      & .hover-bg-accent {
        background-color: $accent !important;
      }
      & .hover-text-secondary {
        color: $secondary !important;
        .q-icon {
          color: $secondary !important;
        }
      }
      & .card-icon {
        color: $secondary !important;
        .q-icon {
          color: $secondary !important;
        }
      }
      & .card-bg-circle {
        color: $accent;
        transform: scale(1.3);
      }
    }
  }
  &-bordered {
    border-width: map-get($borders, 'normal');
    border-style: solid;
    border-color: $border-color;
    &-dashed {
      border-style: dashed;
    }
  }
}
