@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.q {
  &-stepper {
    &.with-overflow {
      & .q-panel-parent {
        margin: -8px !important;
        & > * {
          padding: 8px !important;
        }
      }
    }

    &--vertical {
      & .q-stepper {
        &__step {
          min-height: 64px;
        }
      }
    }

    &:not(.stepper-dark) {
      // default light stepper design
      & .q-stepper {
        &__title {
          color: $primary-500;
        }
        &__tab {
          &--active {
            & .q-stepper {
              &__title {
                color: $primary !important;
              }
            }
          }
        }
      }
    }

    &.stepper-dark {
      & .q-stepper {
        &__title {
          color: white;
        }
        &__tab {
          &--active {
            & .q-stepper {
              &__title {
                color: white !important;
              }
            }
          }
        }
        &__dot {
          &:before,
          &:after {
            background: $primary-400 !important;
          }
        }
      }
    }

    &.stepper-request {
      & .q-stepper {
        &__dot {
          color: $primary;
          border: map-get($borders, 'lg') solid $primary-200;
        }
      }
      & .q-stepper__tab--active {
        & .q-stepper {
          &__dot {
            color: $primary-200;
          }
        }
      }
      & .q-stepper__tab--done {
        & .q-stepper {
          &__dot {
            color: $primary-200;
          }
        }
      }
    }

    &.max-640 {
      width: 700px;
      max-width: 700px;
      & .q-stepper {
        &__step-inner {
          width: 700px;
        }
        &__dot {
          font-size: map-get($subtitle1, 'size');
          font-weight: $font-medium;
        }
      }
    }

    &:not(.stepper-lg) {
      & .q-stepper {
        &__title {
          font-size: map-get($subtitle1, 'size');
        }
        &__dot {
          width: 32px;
          min-width: 32px;
          height: 32px;
          & span {
            font-size: map-get($body1, 'size');
          }
        }
      }
    }

    &.stepper-lg {
      & .q-stepper {
        &__title {
          font-size: map-get($subtitle1, 'size');
        }
        &__dot {
          width: 40px;
          min-width: 40px;
          height: 40px;
          & span {
            font-size: map-get($subtitle1, 'size');
          }
        }
      }
    }

    &__title {
      font-family: 'TTNormsPro-Md';
      font-weight: $font-medium;
      font-size: map-get($body1, 'size');
      letter-spacing: map-get($letter-spacing, 'normal');
    }

    &:not(.stepper-request) {
      &:not(.no-unset) {
        & .q-stepper {
          &__tab {
            flex: auto;
            padding: 8px 24px !important;
            &:first-child,
            &:last-child {
              flex: unset;
            }
          }
        }
      }
    }

    &__step {
      &-inner {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      &.full {
        &-width {
          & .q-stepper {
            &__step {
              &-content {
                padding: 0 !important;
                width: 100% !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
              }
            }
          }
        }
        &-inner {
          & .q-stepper {
            &__step {
              &-inner {
                padding: 12px;
                width: 100% !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
              }
            }
          }
        }
      }
    }

    & .tab-no-padding {
      & .q-stepper {
        &__tab {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md-max) {
  .q {
    &-stepper {
      &.max-640 {
        & .q-stepper__step-inner {
          width: auto;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-sm-max) {
  .q {
    &-stepper {
      &__tab {
        &:first-child,
        &:last-child {
          flex: auto;
        }
      }
    }
  }
}
