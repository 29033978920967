@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.q-badge {
  padding: 2px 8px;
  border-radius: 1em;
  font-family: 'TTNormsPro-Md';
  font-weight: $font-medium;
  letter-spacing: normal;
  // default medium size
  height: 24px;
  font-size: map-get($body2, 'size');
}

.badge {
  &-sm {
    // padding: 6px 8px;
    height: 22px;
    font-size: map-get($caption, 'size');
  }

  &-md {
    height: 24px;
    font-size: map-get($body2, 'size');
  }
  &-lg {
    height: 32px;
    font-size: map-get($body2, 'size');
  }

  /* Colors */
  &-green {
    background-color: $active-bg !important;
    color: $active-color !important;
    & .q-icon {
      color: $active-color !important;
    }
  }
  &-negative {
    background-color: $negative-bg !important;
    color: $negative-color !important;
    & .q-icon {
      color: $negative-color !important;
    }
  }
  &-warning {
    background-color: $warning-bg !important;
    color: $warning-color !important;
    & .q-icon {
      color: $warning-color !important;
    }
  }
  &-grey {
    background-color: $disable-bg !important;
    color: $disable-color !important;
    & .q-icon {
      color: $disable-color !important;
    }
  }
  &-blue {
    background-color: $blue-bg !important;
    color: $blue-color !important;
    & .q-icon {
      color: $blue-color !important;
    }
  }

  &-text {
    &-active {
      color: $active-color !important;
    }
    &-negative {
      color: $negative-color !important;
    }
    &-warning {
      color: $warning-color !important;
    }
    &-disable {
      color: $disable-color !important;
    }
    &-blue {
      color: $blue-color !important;
    }
  }

  &-bg {
    &-active {
      background-color: $active-bg !important;
    }
    &-negative {
      background-color: $negative-bg !important;
    }
    &-warning {
      background-color: $warning-bg !important;
    }
    &-disable {
      background-color: $disable-bg !important;
    }
    &-blue {
      background-color: $blue-bg !important;
    }
  }

  &-border {
    &-active {
      border-color: $active-color !important;
    }
    &-negative {
      border-color: $negative-color !important;
    }
    &-warning {
      border-color: $warning-color !important;
    }
    &-disable {
      border-color: $disable-color !important;
    }
    &-blue {
      border-color: $blue-color !important;
    }
  }
}
