@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
.text {
  &-inherit {
    color: inherit;
  }
  &-primary {
    &-1 {
      color: $primary-1;
    }
    &-50 {
      color: $primary-50;
    }
    &-100 {
      color: $primary-100;
    }
    &-200 {
      color: $primary-200;
    }
    &-300 {
      color: $primary-300;
    }
    &-400 {
      color: $primary-400;
    }
    &-500 {
      color: $primary-500;
    }
    &-600 {
      color: $primary-600;
    }
    &-700 {
      color: $primary-700;
    }
    &-800 {
      color: $primary-800;
    }
    &-900 {
      color: $primary-900;
    }
  }
  &-secondary {
    &-50 {
      color: $secondary-50;
    }
    &-100 {
      color: $secondary-100;
    }
    &-200 {
      color: $secondary-200;
    }
    &-300 {
      color: $secondary-300;
    }
    &-400 {
      color: $secondary-400;
    }
    &-500 {
      color: $secondary-500;
    }
    &-600 {
      color: $secondary-600;
    }
    &-700 {
      color: $secondary-700;
    }
    &-800 {
      color: $secondary-800;
    }
    &-900 {
      color: $secondary-900;
    }
  }
  &-warning {
    &-50 {
      color: $warning-50;
    }
    &-100 {
      color: $warning-100;
    }
    &-200 {
      color: $warning-200;
    }
    &-300 {
      color: $warning-300;
    }
    &-400 {
      color: $warning-400;
    }
    &-500 {
      color: $warning-500;
    }
    &-600 {
      color: $warning-600;
    }
    &-700 {
      color: $warning-700;
    }
    &-800 {
      color: $warning-800;
    }
    &-900 {
      color: $warning-900;
    }
  }
  &-negative {
    &-50 {
      color: $negative-50;
    }
    &-100 {
      color: $negative-100;
    }
    &-200 {
      color: $negative-200;
    }
    &-300 {
      color: $negative-300;
    }
    &-400 {
      color: $negative-400;
    }
    &-500 {
      color: $negative-500;
    }
    &-600 {
      color: $negative-600;
    }
    &-700 {
      color: $negative-700;
    }
    &-800 {
      color: $negative-800;
    }
    &-900 {
      color: $negative-900;
    }
  }
  &-positive {
    &-50 {
      color: $positive-50;
    }
    &-100 {
      color: $positive-100;
    }
    &-200 {
      color: $positive-200;
    }
    &-300 {
      color: $positive-300;
    }
    &-400 {
      color: $positive-400;
    }
    &-500 {
      color: $positive-500;
    }
    &-600 {
      color: $positive-600;
    }
    &-700 {
      color: $positive-700;
    }
    &-800 {
      color: $positive-800;
    }
    &-900 {
      color: $positive-900;
    }
  }
  &-logo {
    &1 {
      color: map-get($gm-logo, 'color-1');
    }
    &2 {
      color: map-get($gm-logo, 'color-2');
    }
    &3 {
      color: map-get($gm-logo, 'color-3');
    }
  }
}
